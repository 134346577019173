@use '../abstracts/variables' as *;

.checkbox {

  label{
    display: flex;
    // height: 24px;
  }

  input {
    display: none;
  }

  &__btn {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.unchecked {
      color: $senary-bg-color;
    }
    
    &.checked {
      color: $primary-color;
    }
    
    svg {
      display: block;
    }
  }

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-left: 5px;
    color: $septenary-text-color;
  }

  &__error {
    font-size: 0.75rem;
    color: $secondary-color;
    padding: 5px 0;
  }

  &--small {

    .checkbox__btn {
      width: 18px;
      height: 18px;

      svg {
        font-size: 1.125rem;
      }
    }
  }
}