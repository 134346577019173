@use '../abstracts/variables' as *;

.landing-header {
  padding: 28px 0;
  background-color: $primary-light-color;
  z-index: 100;
  position: fixed;
  width: 100%;
  top: 0;
  transition: padding .3s ease;
  backface-visibility: hidden;
  
  .landing-container {
    display: flex;
    align-items: center;
  }

  .landing-menu-wrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;

    @media (max-width: $break-lg-max) {
      display: none;
    }
    
    .landing-menu {
      display: flex;
      align-items: center;

      li {
        
        button {
          font-family: $ff-tertiary-medium;
          font-size: 0.875rem;
          line-height: 1.571;
          padding: 8px 20px;
          color: #0E1E40;
          background-color: transparent;
          border: 0;
          outline: 0;
        }
      }
    }
  }

  .landing-menu-box {
    position: absolute;
    top: 120px;
    left: 50px;
    width: 200px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(#000, 0.2);
    border-radius: 10px;
    transition: all .3s ease;
  }

  .landing-menu-box-content {
    display: flex;

    &.hide {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    ul {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    margin-left: auto;

    .menu-trigger {
      border-radius: 10px;
      padding: 2px 0;
      display: none;
      color: #10C0B5;
      // background-color: lighten(#e6e9ee, 5);
      margin-right: 22px;

      svg {
        display: block;
        color: inherit;
      }

      @media (max-width: $break-lg-max) {
        // display: block;
      }
    }

    .link {
      display: flex;
      align-items: center;
      margin-right: 22px;
      color: #10C0B5;
      font-family: $ff-tertiary-medium;
      font-size: 0.75rem;
      font-weight: 500;
      letter-spacing: 0.86px;

      svg {
        display: block;
        margin-right: 8px;
        font-size: 1.25rem;
        color: #3CCBC2;
      }

      @media (max-width: $break-md-max) {
        // display: none;
        margin-right: 0;
      }
    }

    .button {
      transition: height .3s ease, background-color .3s ease;

      &.landing-gray {
        // margin-right: 21px;
      }

      @media (max-width: $break-lg-max) {
        display: none;
      }
    }

    .mobile-menu-trigger {
      display: none;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border: 1px solid $primary-color;
      color: $primary-color;
      border-radius: 3px;
      transition: background-color .3s ease, color .3s ease;

      &:hover {
        background-color: $primary-color;
        color: $primary-light-color;
      }

      @media (max-width: $break-md-max) {
        display: flex;
      }
    }

    .lang-wrapper {
      margin-left: 20px;
      position: relative;

      &__selected {
        font-size: 0.875rem;
        line-height: 1.214;
        font-family: $ff-tertiary-medium;
        color: #0F2130;
        display: flex;
        align-items: center;
        opacity: 0.5;
        text-transform: capitalize;
        cursor: pointer;
        user-select: none;

        svg {
          font-size: 1.125rem;
          display: block;
        }
      }

      &__dropdown {
        position: absolute;
        top: calc(100% + 10px);
        right: -15px;
        width: 60px;
        background-color: #fff;
        border-radius: 6px;
        padding: 5px 0;
        box-shadow: 0 0 10px rgba($primary-dark-color, 0.12);

        a {
          display: block;
          color: #0F2130;
          opacity: 0.5;
          text-align: center;
          padding: 5px 0;
          font-size: 0.875rem;
          transition: background-color .3s ease;

          &:hover,
          &.active {
            background-color: darken(#f2f2f2, 10);
          }
        }
      }
    }

    .languages {
      display: flex;
      align-items: center;
      margin-left: 21px;

      @media (max-width: $break-lg-max) {
        margin-left: 0;
      }
      @media (max-width: $break-md-max) {
        margin-left: 15px;
      }

      a {
        color: #000;
        font-size: 0.875rem;
        margin-right: 10px;
        opacity: 0.7;

        &.active {
          font-weight: 700;
          opacity: 1;
          color: #006EFF;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &.sticky {
    padding: 12px 0;
    box-shadow: 0 2px 4px 0 rgba($primary-dark-color, 0.12);
    z-index: 100;

    .landing-logo {

      p {
        font-size: 1.25rem;
        line-height: 1.2;
      }

      img {
        width: 26px;
        height: 27px;
      }
    }

    .landing-header__right {

      .button {
        height: 36px;
      }
    }
  }
}

// @use '../abstracts/variables' as *;

// .landing-header {
//   padding: 12px 0;
//   background-color: $primary-light-color;
//   z-index: 100;
//   position: fixed;
//   width: 100%;
//   top: 0;
//   transition: padding .3s ease;
//   backface-visibility: hidden;

//   &__top {
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     margin-bottom: 12px;

//     a.link {
//       font-size: 0.8125rem;
//       line-height: 1.23;
//       color: #879097;
//       letter-spacing: 0.3px;
//       transition: color .3s ease;

//       &:hover {
//         color: #16B5D2;
//       }
//     }

//     .divider {
//       width: 1px;
//       height: 12px;
//       background-color: #D2D2D2;
//       margin: 0 10px;
//     }
//   }
  
//   &__main {
//     display: flex;
//     align-items: center;
//   }

//   .landing-menu-wrapper {
//     margin-left: 38px;
//     display: flex;
//     align-items: center;

//     @media (max-width: $break-lg-max) {
//       display: none;
//     }
    
//     .landing-menu {
//       display: flex;
//       align-items: center;

//       li {
        
//         button {
//           font-family: $ff-tertiary-medium;
//           font-weight: 500;
//           font-size: 0.875rem;
//           line-height: 1.571;
//           padding: 8px 20px;
//           color: #0E1E40;
//           background-color: transparent;
//           border: 0;
//           outline: 0;
//         }
//       }
//     }
//   }

//   .landing-menu-box {
//     position: absolute;
//     top: 120px;
//     left: 50px;
//     width: 200px;
//     padding: 20px;
//     background-color: #fff;
//     box-shadow: 0 0 10px rgba(#000, 0.2);
//     border-radius: 10px;
//     transition: all .3s ease;
//   }

//   .landing-menu-box-content {
//     display: flex;

//     &.hide {
//       position: absolute;
//       opacity: 0;
//       z-index: -1;
//     }

//     ul {
//       margin-right: 15px;

//       &:last-child {
//         margin-right: 0;
//       }
//     }
//   }

//   &__right {
//     display: flex;
//     align-items: center;
//     margin-left: auto;

//     .menu-trigger {
//       border-radius: 10px;
//       padding: 2px 0;
//       display: none;
//       color: #10C0B5;
//       // background-color: lighten(#e6e9ee, 5);
//       margin-right: 22px;

//       svg {
//         display: block;
//         color: inherit;
//       }

//       @media (max-width: $break-lg-max) {
//         display: block;
//       }
//     }

//     .button {
//       transition: height .3s ease, background-color .3s ease, color .3s ease;
//       max-width: 100%;
//       min-width: 0;

//       &.landing-primary {
//         padding: 0 16px 0 17px;

//         .icon {
//           margin-right: 10px;
//         }
//       }

//       &.button--outline-primary {
//         height: 38px;
//         color: #16B5D2;
//         border-color: #19C6E6;
//         margin-right: 20px;
//         border-radius: 6px;
//         padding: 0 10px;
//         font-size: 0.8125rem;
//         font-family: $ff-tertiary-bold;

//         &:hover {
//           background-color: #19C6E6;
//           color: #fff;
//         }
//       }

//       span.icon {

//         svg {
//           font-size: 1.25rem;
//         }
//       }

//       span.text {
//         text-overflow: ellipsis;
//         overflow: hidden;
//         white-space: nowrap;
//       }

//       @media (max-width: $break-lg-max) {
//         display: none;
//       }
//     }

//     .mobile-menu-trigger {
//       display: none;
//       align-items: center;
//       justify-content: center;
//       width: 36px;
//       height: 36px;
//       border: 1px solid $primary-color;
//       color: $primary-color;
//       border-radius: 3px;
//       transition: background-color .3s ease, color .3s ease;

//       &:hover {
//         background-color: $primary-color;
//         color: $primary-light-color;
//       }

//       @media (max-width: $break-md-max) {
//         display: flex;
//       }
//     }
//   }

//   &.sticky {
//     padding: 6px 0 12px;
//     box-shadow: 0 2px 4px 0 rgba($primary-dark-color, 0.12);
//     z-index: 100;

//     .landing-logo {

//       p {
//         font-size: 1.25rem;
//         line-height: 1.2;
//       }

//       img {
//         width: 26px;
//         height: 27px;
//       }
//     }

//     .landing-header__right {

//       .button {
//         height: 36px;
//       }
//     }
//   }

//   .lang-wrapper {
//     position: relative;

//     &__selected {
//       font-size: 0.875rem;
//       line-height: 1.214;
//       font-family: $ff-tertiary-medium;
//       font-weight: 500;
//       color: #0F2130;
//       display: flex;
//       align-items: center;
//       opacity: 0.5;
//       text-transform: capitalize;
//       cursor: pointer;
//       user-select: none;

//       svg {
//         font-size: 1.125rem;
//         display: block;
//       }
//     }

//     &__dropdown {
//       position: absolute;
//       top: calc(100% + 10px);
//       right: -15px;
//       width: 60px;
//       background-color: #fff;
//       border-radius: 6px;
//       padding: 5px 0;
//       box-shadow: 0 0 10px rgba($primary-dark-color, 0.12);

//       a {
//         display: block;
//         color: #0F2130;
//         opacity: 0.5;
//         text-align: center;
//         padding: 5px 0;
//         font-size: 0.875rem;
//         transition: background-color .3s ease;

//         &:hover,
//         &.active {
//           background-color: darken(#f2f2f2, 10);
//         }
//       }
//     }
//   }
// }