@use '../abstracts/variables' as *;

.side-panel {
  display: flex;
  z-index: 10;

  &__menu {
    width: 55px;
    border-right: 1px solid $secondary-border-color;
    padding-top: 46px;
    padding: 20px 15px 5px;
    display: flex;
    flex-direction: column;

    .trigger {
      font-size: 1.5rem;
      color: rgba($primary-dark-color, 0.2);
      cursor: pointer;
      margin-bottom: 15px;
      transform: rotate(180deg);
      transition: color .3s ease, transform .9s ease;

      &:hover {
        color: $primary-color;
      }
    }

    ul {
      border-top: 1px solid rgba($primary-dark-color, 0.2);
      padding-top: 20px;

      li {
        font-size: 1.5rem;
        margin-bottom: 18px;

        svg {
          display: block;
        }

        a {
          color: rgba($primary-dark-color, 0.2);
          transition: color .3s ease;

          &:hover,
          &.active {
            color: $primary-color;

            span.badge {
              background-color: $primary-color;
            }
          }

          span.badge-wrap {
            position: relative;
            display: block;
          }

          span.badge {
            display: block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $senary-color;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: -3px;
            font-size: 9px;
            font-weight: 700;
            color: $primary-light-color;
            transform: translateY(-50%);
            transition: background-color .3s ease;
          }
        }

        .disabled {
          color: rgba($primary-dark-color, 0.2);
          cursor: pointer;
        }
      }
    }

    .back-to-top {
      margin-top: auto;
    }
  }

  &__main {
    flex: 1;
    overflow-y: auto;
    /* SCROLLING TASK */
    // overflow-y: visible;
    // height: 100%; 
    /* SCROLLING TASK */
  }

  &--2 {

    .side-panel__menu {
      height: calc(100% - 75px);
    }
  }

  &__upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary-light-color, 0.9);
    padding: 15px;

    &.active {

      .side-panel__upload_inner {
        border-color: $primary-color;
      }
    }
    
    &_inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 100%;
      border-radius: 3px;
      border: 2px dashed $senary-border-color;
    }

    &_close {
      position: absolute;
      top: 25px;
      right: 25px;
      color: $senary-border-color;
      cursor: pointer;
      transition: color .3s ease;
      z-index: 10;
      opacity: 0.87;

      &:hover {
        color: darken($senary-border-color, 5);
      }

      &:active {
        color: darken($senary-border-color, 10);
      }
      
      svg {
        font-size: 1.5rem;
      }
    }

    .info {
      font-size: 1.1875rem;
      font-weight: 500;
      text-align: center;
      color: $quinary-dark-color;
      margin-bottom: 24px;
    }

    .or {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 26px;
  
      .line {
        height: 2px;
        width: 26px;
        background-color: $secondary-light-color;
      }
  
      p {
        font-size: 0.875rem;
        color: $quinary-dark-color;
        margin: 0 18px;
      }
    }

    .button {

      .icon {
        font-size: 1.25rem;
      }

      .text {
        font-weight: 900;
        letter-spacing: 0.35px;
      }
    }

    .file-input {
      display: none;
    }

    &_error {
      color: $secondary-color;
      margin-top: 15px;
      font-size: 0.875rem;
    }

    .drag-n-drop-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}