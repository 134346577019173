@use '../abstracts/variables' as *;

.landing-deadlines {
  overflow: hidden;
  padding-top: 102px;
  position: relative;

  .landing-container {
    padding: 0 100px 231px;
    position: relative;

    @media (max-width: $break-lg-max) {
      padding: 0 57px 231px;
    }

    @media (max-width: $break-sm-max) {
      padding: 0 15px 231px;
    }
  }

  &__inner {
    max-width: 366px;
    position: relative;

    @media (min-width: 1300px) {
      max-width: 550px;
    }

    .landing-label {
      margin-bottom: 29px;
    }

    h2 {
      margin-bottom: 31px;
      color: $text-color-11;
    }

    p {
      font-family: $ff-tertiary;
      line-height: 1.3125;
      color: $quaternary-dark-color;
    }

    &_icons {
      position: relative;
      padding-left: 96px;
      margin-bottom: 58px;

      img {
        position: absolute;
        z-index: -1;
        top: -42px;
        right: -59px;
        max-width: none;
      }
    }

    &_dates {
      display: flex;
      margin-left: -9px;
      margin-top: 140px;

      @media (max-width: $break-sm-max) {
        margin-left: 25px;
      }

      .date {
        font-family: $ff-quaternary;
        position: relative;
        margin-right: 39px;

        &__month {
          font-size: 0.6875rem;
          font-weight: 700;
          color: $quinary-dark-color;
        }

        &__day {
          width: 45px;
          height: 45px;
          background-color: $bg-color-11;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.25rem;
          font-weight: 700;
          color: rgba($primary-light-color, 0.7);
          position: absolute;
          right: 13px;
          top: 100%;
        }
      }
    }

    &_calendar {
      position: absolute;
      top: -15px;
      left: 453px;
      max-width: none;
      display: none;

      @media (max-width: 1200px) {
        display: block;
      }
    }
  }

  &__calendar {
    position: absolute;
    top: 88px;
    left: 50%;
    max-width: none;
    
    img {
      max-width: none;
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  &__dots {
    width: 86px;
    height: 206px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 83px;
    right: calc(100% - 110px);
    z-index: -1;

    span {
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: rgba($primary-color, 0.1);
      margin-right: 14px;
      margin-bottom: 14px;

      &:nth-child(5n+5) {
        margin-right: 0;
      }
    }
  }
}