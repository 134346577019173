@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.form-input {
  margin-bottom: 15px;
  
  &__inner {
    position: relative;

    &--align-horiz {
      display: flex;
      align-items: center;
      margin-bottom: 11px;

      label {
        width: 200px;
        margin-right: 15px;
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 0;
        color: $septenary-text-color;
      }

      .form-input__field {
        flex: 1;
        margin-bottom: 0;
      }

      @media (max-width: $break-sm-max) {
        display: block;

        label {
          margin-bottom: 5px;
        }
      }
    }
  }

  &.error {

    .form-input__field {
      border-color: $secondary-color;
    }
  }

  &.with-icon {

    .form-input__field {
      padding-left: 36px;
    }

    &.form-input--white-background {

      .form-input__field {
        padding-left: 42px;
      }
    }
  }

  &.disabled {
    opacity: 0.6;
  }

  &__icon {
    color: $nonary-text-color;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      display: block;
      font-size: 1.5rem;
    }
  }

  &__label {
    display: block;
    margin-bottom: 5px;
  }

  &__field {
    border: 0;
    outline: 0;
    border-radius: 3px;
    background-color: $primary-bg-color;
    font-family: $ff-primary;
    font-size: 0.875rem;
    font-weight: 700;
    color: $nonary-text-color;
    padding: 8px 14px;
    width: 100%;
    display: block;
    transition: border .3s ease;

    @include placeholder {
      color: $nonary-text-color;
    }

    &:focus {
      border-color: $primary-color !important;
    }
  }

  &__info {
    font-size: 0.75rem;
    margin-top: 5px;
  }

  &__error {
    font-size: 0.75rem;
    color: $secondary-color;
    padding: 5px 0;
  }

  &--medium {

    .form-input__field {
      font-weight: 500;
    }
  }

  &--size-medium {

    .form-input__field {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &--small {

    .form-input__field {
      padding: 2px 5px;
      border-bottom: 1px solid $primary-dark-color;
      background-color: $primary-light-color;
      color: $primary-text-color;
      border-radius: 0;
    }
  }
  &--white-background {

    &.form-input--size-medium {

      .form-input__field {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .form-input__field {
      background-color: $primary-light-color;
      border: 2px solid transparent;
      color: rgba($tertiary-dark-color, 0.5);
      transition: border-color .3s ease;
      
      @include placeholder {
        color: rgba($tertiary-dark-color, 0.5);
      }

      &:focus {
        border-color: $primary-color;
      }
    }

    .form-input__icon {
      left: 11px;

      svg {
        color: $secondary-light-color;
      }
    }
  }

  &--color-light {

    .form-input__field {
      color: $senary-text-color;

      @include placeholder {
        color: $senary-text-color;
      }
    }
  }

  &--edit-mode {
    
    .form-input__field {
      background-color: rgba($primary-color, 0.12);
      padding: 5px 10px 4px;
      border: 1px solid transparent;
    }
  }

  &--dashboard-modal {
    position: relative;
    padding-top: 19px;

    .form-input__label {
      font-size: 0.75rem;
      color: $quinary-text-color;
      position: absolute;
      top: 5px;
      cursor: pointer;
      z-index: 1;
      transition: top .2s ease, color .2s ease;
      width: 100%;

      &.active {
        top: -19px;
        color: $primary-color;
      }
    }
    
    .form-input__field {
      background-color: transparent;
      border-bottom: 1px solid $tertiary-border-color;
      border-radius: 0;
      font-size: 0.875rem;
      font-weight: 300;
      color: $primary-text-color;
      padding: 3px 0;
      transition: border .3s ease;
      color: #000;

      &:focus {
        border-bottom-color: $primary-color;
      }

      @include placeholder {
        font-size: 0.75rem;
        color: $quinary-text-color;
      }
    }
  }

  &--editable-on-hover {
    margin-bottom: 0;

    .form-input__field {
      border: 1px solid transparent;
      background-color: transparent;
      width: auto;
      font-size: 1rem;
      font-weight: 700;
      color: $primary-text-color;
      padding: 2px;

      &:hover {
        border-color: $primary-color;
      }
    }

  }

  &--profile-mode {
    margin-bottom: 21px;

    .form-input__field {
      border-bottom: 1px solid $quaternary-border-color;
      border-radius: 0;
      background-color: transparent;
      padding: 8px 0;
      font-size: 1rem;
      color: $septenary-text-color;
      font-weight: 400;
      margin-bottom: 11px;
    }

    .form-input__label {
      font-size: 0.875rem;
      color: $quinary-text-color;
    }
  }

  &--icon-small {

    .form-input__icon {

      svg {
        font-size: 1.25rem;
      }
    }
  }

  &--form-el {
    
    .form-input__field {
      background-color: $form-el-bg-color-1;
      border: 1px solid $form-el-border-color-1;
      color: $primary-dark-color;
      border-radius: 2px;
      height: 36px;
      font-size: 0.9375rem;
      font-weight: 400;
      padding: 0 14px;
      
      @include placeholder {
        color: $form-el-text-color-1;
      }
    }

    .form-input__label {
      font-size: 0.9375rem;
      font-weight: 400;
      color: $secondary-text-color;
      margin-bottom: 10px;
    }
  }

  &--form-el-optional {
    
    .form-input__field {
      background-color: $primary-light-color;
      border: 1px solid $tertiary-bg-color;
      color: $primary-dark-color;
      border-radius: 2px;
      height: 36px;
      font-size: 0.9375rem;
      font-weight: 400;
      padding: 0 14px;
      
      @include placeholder {
        color: $form-el-text-color-1;
      }
    }

    .form-input__label {
      font-size: 0.9375rem;
      font-weight: 400;
      color: $senary-color;
      margin-bottom: 10px;
    }
  }

  &--transparent {
    
    .form-input__field {
      background-color: transparent;
      border: 0;
      color: $secondary-text-color;
      border-radius: 0;
      height: auto;
      font-size: 0.875rem;
      font-weight: 400;
      padding: 0;
      
      @include placeholder {
        color: $quinary-text-color;
      }
    }
  }

  &--popup-mode {

    .form-input__field {
      background-color: $primary-light-color;
      font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: 0.18px;
      color: $secondary-text-color;
      padding: 14px 11px;
    }
  }

  &--thick-border {

    .form-input__field {
      border-width: 2px;
    }
  }

  &--error {

    .form-input__field {
      border-color: $secondary-color;
    }
  }
}

.input-icon-button {
  cursor: pointer;
  margin-top: 25px;
}