@use '../abstracts/variables' as *;

.landing-toolbox {
  background: rgb(253,254,255);
  background: linear-gradient(171deg, rgba(253,254,255,1) 0%, rgba(246,249,251,1) 100%);
  padding: 98px 0 78px;
  overflow: hidden;

  &__head {
    margin-bottom: 116px;
    text-align: center;

    .landing-label {
      margin-bottom: 22px;
    }

    h2 {
      margin-bottom: 26px;
      color: #0C1C42;
    }

    p {
      max-width: 457px;
      margin: 0 auto;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;

    .landing-icon-box {
      margin-left: 11px;
      margin-right: 11px;
    }
  }
}