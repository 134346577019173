@use '../abstracts/variables.scss' as *;

.highlights-section {
  overflow: hidden;
  
  .highlighted-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    min-height: 473px;

    @media (max-width: $break-md-max) {
      min-height: auto;
    }

    &.with-absolute-img {
      padding: 65px 0;

      img {
        max-height: 473px;
      }

      @media (max-width: $break-md-max) {
        padding: 0;
      }
    }

    &.center-img {

      .highlighted-area__img {
        justify-content: center;
      }
    }

    @media (max-width: $break-md-max) {
      margin-bottom: 100px;
    }

    &__img,
    &__content {
      width: 100%;
      max-width: 471px;

      @media (min-width: $break-lg-min) {
        max-width: 665px;
      }
    }

    &__img {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      align-self: stretch;

      img {
        max-width: 100%;
        display: block;

        &.absolute {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          max-width: none;
          // width: 100%;
        }
      }

      @media (max-width: $break-md-max) {
        display: none;
      }
    }

    &__content {
      margin-left: 88px;

      @media (max-width: $break-md-max) {
        max-width: 100%;
        margin-left: 0;
      }
      
      h2 {
        font-family: $ff-tertiary-bold;
        font-size: 2.0625rem;
        line-height: 1.21;
        color: #0E1E40;
        margin-bottom: 13px;
        max-width: 432px;
      }

      .description {
        font-size: 1rem;
        font-family: $ff-tertiary-medium;
        line-height: 1.625;
        color: #5D606B;
        margin-bottom: 30px;

        span {
          color: #0E1E40;
          font-weight: 500;
        }
      }

      ul {

        li {
          position: relative;
          padding-left: 43px;
          font-size: 1rem;
          line-height: 1.3125;
          color: #5D606B;
          margin-bottom: 16px;
          padding-top: 2px;
          padding-bottom: 2px;

          svg {
            position: absolute;
            top: -2px;
            left: -2px;
            color: #19E678;
            font-size: 1.8rem;
          }
        }
      }
    }

    &--2 {

      .highlighted-area__img {
        order: 2;
        justify-content: flex-end;

        img.absolute {
          right: auto;
          left: 0;
        }
      }

      .highlighted-area__content {
        order: 1;
        margin-left: 0;
        margin-right: 25px;

        @media (max-width: $break-md-max) {
          margin-right: 0;
        }
      }
    }

    &--last {
      margin-top: 100px;
    }
  }
}