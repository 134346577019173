@use '../abstracts/variables.scss' as *;

.feature-highlights {
  padding: 77px 0 146px;

  &__head {
    text-align: center;
    margin-bottom: 109px;

    h1 {
      font-family: $ff-tertiary-bold;
      font-size: 2.375rem;
      line-height: 1.21;
      margin-bottom: 13px;
      color: #0E1E40;
    }

    p {
      font-size: 1rem;
      line-height: 1.3125;
      color: #5D606B;
    }
  }

  &__body {

    .highlighted-area {

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}