@use '../abstracts/variables.scss' as *;

.features-slider {
  background-color: #091937;
  padding: 79px 0 118px;

  &__head {
    color: #fff;

    p {
      font-family: $ff-tertiary-bold;
      font-size: 0.9375rem;
      letter-spacing: 2.5px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    h2 {
      margin-bottom: 59px;
      max-width: 483px;
    }
  }

  &__body {
    width: calc(100% + 24px);
    margin-left: -12px;

    @media (max-width: $break-sm-max) {
      width: 100%;
      margin-left: 0;
    }

    .alice-carousel {

      &__stage {
        display: flex;
      }

      &__stage-item {
        padding: 0 12px;
        flex: 1 0 auto;
        height: auto;

        @media (max-width: $break-sm-max) {
          padding: 0;
        }
      }

      .item {
        background-color: #fff;
        padding: 26px 33px 36px;
        border-radius: 6px;
        height: 100%;
        display: flex;
        flex-direction: column;

        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          background-color: #19E678;
          color: #fff;
          margin-bottom: 19px;
        }

        &__title {
          font-size: 1.1875rem;
          font-family: $ff-tertiary-bold;
          line-height: 1.26;
          margin-bottom: 12px;
          color: #0E1E40;
        }

        &__subtitle {
          font-size: 1rem;
          line-height: 1.3125;
          color: #5D606B;
          margin-bottom: 36px;
        }

        &__btn-wrapper {
          margin-top: auto;
        }
      }

      &__prev-btn,
      &__next-btn {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 116px;
        left: -57px;
        padding: 0;

        @media (max-width: 1550px) {
          top: -44px;
          left: 12px;
        }

        .slider-btn {
          cursor: pointer;
          width: 36px;
          height: 36px;
          
          svg {
            font-size: 2.25rem;
            color: #848D9A;
            display: block;
          }

          &.disabled {
            opacity: 0.5;
            cursor: default;
          }
        }
      }

      &__next-btn {
        right: -57px;
        left: auto;

        @media (max-width: 1550px) {
          right: 12px;
        }
      }
    }
  }
}