@keyframes moveRight {
  0% {
    left: 0;
  }
  75% {
    width: 15%;
  }
  100% {
    left: 150%;
  }
}

@keyframes moveRight2 {
  0% {
    left: -120%;
  }
  75% {
    width: 15%;
  }
  100% {
    left: 100%;
  }
}


@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes tooltip {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    transform-origin: left;
  }
  1% {
    opacity: 0;
    transform: translateX(-50%);
    transform-origin: left;
  }
  100% {
    opacity: 1;
    transform: translateX(-50%);
    transform-origin: left;
  }
}

@keyframes tooltipLeft {
  0% {
    opacity: 0;
    transform: translateY(-50%);
    transform-origin: left;
  }
  1% {
    opacity: 0;
    transform: translateY(-50%);
    transform-origin: left;
  }
  100% {
    opacity: 1;
    transform: translateY(-50%);
    transform-origin: left;
  }
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0;
  }
  100%{
    background-position: 468px 0;
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
