@use '../abstracts/variables' as *;

.dashboard-column {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;

  &__inner {
    width: calc(100% - 7px);

    &:last-child {
      width: 100%;
    }

    .status-el.empty {
      opacity: 0;
    }
  }

  .handle {
    width: 7px;
    height: 100%;
    cursor: col-resize;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.cursor-normal {
      cursor: default;
    }

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 60%;
      background-color: #e5e5e5;
    }

    &--before {
      right: auto;
      left: -7px;
    }
  }

  .sort {
    display: inline-flex;
    align-items: center;
    color: $color-13;
    font-size: 0.875rem;
    cursor: pointer;
    width: 100%;
    text-align: center;
    justify-content: center;
    user-select: none;
    // transition: color .3s ease;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      color: $color-12;
    }

    &:active {

      p {
        color: $senary-text-color !important;
      }
    }

    svg {
      display: block;
      font-size: 1rem;
      margin-left: 3px;
    }

    &.active {
      color: $color-12;
      
      p {
        font-weight: 700;
        color: $color-12;
      }
    }
  }

  .link-column-to-variable {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);

    button {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      border-radius: 3px;
      outline: 0;
      background-color: #f2f2f2;
      color: #333;
      cursor: pointer;

      svg {
        display: block;
        font-size: 1.25rem;
        color: inherit;
      }

      &:hover {
        background-color: darken(#f2f2f2, 10);
      }
    }
  }

  &.col-1 {
    flex: 1;
    padding-left: 33px;
    position: relative;

    // &.checkbox-col {
    //   padding-left: 0;

    //   .dashboard-column__inner {
    //     padding-left: 0 !important;
    //   }
    // }

    .sort {
      justify-content: flex-start;
    }

    .checkbox {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      .unchecked {
        color: $senary-bg-color;
      }

      .checked {
        color: $primary-color;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: $quinary-text-color;

      svg {
        display: block;
      }

      i {
        font-size: 1.5rem;
      }

      &.contact-index {
        width: 24px;
        text-align: center;
      }
    }
  }

  &.col-2 {
    width: 100px;
    margin-right: 20px;
    font-size: 0.75rem;
    color: $color-12;
  }

  &.col-3 {
    width: 100px;
    margin-right: 20px;
    text-align: center;
  }

  &.col-4 {
    width: 100px;
    text-align: right;
  }

  &.signatures-col-2,
  &.signatures-col-3,
  &.signatures-col-4,
  &.signatures-col-5 {
    width: 100px;
    margin-right: 50px;
    font-size: 0.875rem;
  }

  &.signatures-col-5 {
    width: 120px;
  }
  
  &.signatures-col-4 {
    width: 125px;
  }

  &.signatures-col-5 {
    justify-content: center !important;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  &.last {
    margin-right: 0;
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
    text-align: center;
  }

  &.start {
    justify-content: flex-start;
  }

  &.contact-column-3 {
    padding-left: 20px;
    justify-content: center;

    .num {
      display: flex;
      width: 22px;
      height: 22px;
      align-items: center;
      justify-content: center;
      border: 1px solid $primary-color;
      background-color: rgba($primary-color, 0.2);
      border-radius: 50%;
      color: $primary-color;
      font-weight: 700;
      font-size: 0.8125rem;
    }
  }

  &.template-col {

    .hover {
      display: none;

      svg {
        font-size: 1.125rem;
        color: #0acb93;

        &.off {
          color: #F47458;
        }
      }
    }
  }

  &.attachments-col,
  &.dates-col,
  &.alerts-col,
  &.tasks-col {

    .num {
      display: inline-flex;
      width: 18px;
      height: 18px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 0.75rem;
      font-weight: 700;
      color: #fff;
    }
  }

  &.attachments-col {

    .num {
      background-color: #43AFFD;
    }
  }

  &.tasks-col,
  &.dates-col {

    .num {
      background-color: #A664FC;
    }
  }

  &.alerts-col {

    .num {
      background-color: #FE6084;
    }
  }

  &.tags-col {

    &.multiple {

      .tags--normal {
        display: none;
      }
    }

    &.multiple-active {

      .tags--normal {
        display: flex;
      }
      .tags--inline {
        display: none !important;
      }
    }

    .tags {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--inline {
        flex-direction: row;
        align-items: center;

        svg {
          font-size: 1rem;
          margin-left: 2px;
          display: block;
        }
      }

      .tag {
        display: inline-flex;
        align-items: center;
        height: 20px;
        border-radius: 10px;
        padding: 0 9px;
        color: #fff;
        background-color: rgb(102,102,102);
        align-self: center;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.actions-col {
    padding-right: 17px;

    .more {
      display: block;

      .vert {
        display: none;
      }
      .horiz {
        display: block;
      }
    }

    .handle {
      right: 17px;
    }

    @media (max-width: $break-md-max) {
      position: absolute !important;
      top: 10px;
      right: 10px;
      padding-right: 0;

      .more {
        position: static;

        .vert {
          display: block;
        }
        .horiz {
          display: none;
        }
      }
    }
  }

  &__main {
    cursor: pointer;

    p {

      &.title {
        font-size: 0.9375rem;
        font-weight: 400;
        line-height: 1.2;
        color: $color-12;
      }
      
      &.progress {
        margin-top: 3px;
        font-size: 0.75rem;
        color: $quaternary-text-color;
      }
    }

    .icons-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 6px;

      &.mt-0 {
        margin-top: 0;
      }
      &.mt-15 {
        margin-top: 15px;
      }

      .progress-el-wrapper {
        display: flex;
        align-items: center;

        p {
          font-size: 0.5625rem;
          letter-spacing: 0.04px;
          color: $color-12;
          margin-right: 3px;
        }
      }

      .progress-el {
        margin-right: 19px;
      }

      .icons {
        display: flex;
        align-items: center;

        svg {
          display: block;
          font-size: 1.125rem;
        }

        span {
          margin-right: 10px;

          &.template {
            color: $bg-color-29;
            margin-right: 17px;
          }
          &.template-off {
            color: $color-14;
            margin-right: 17px;
          }
          &.attachments {
            color: $color-15;
          }
          &.tasks {
            color: $color-16;

            svg {
              font-size: 1.3125rem;
            }
          }
          &.dates {
            color: $color-17;

            svg {
              font-size: 1.0625rem;
            }
          }
          &.contacts {
            color: $color-18;

            svg {
              font-size: 1.1875rem;
            }
          }
          &.alert {
            color: $color-19;

            svg {
              font-size: 1.1875rem;
            }
          }
          &.tags {
            color: $color-28;

            svg {
              font-size: 1.1875rem;
            }
          }
        }
      }
    }
  }

  &--actions {
    display: flex;
    align-items: center;

    a {
      display: block;
      position: relative;
      color: $primary-color;
      margin-left: 20px;
      cursor: pointer;

      svg {
        display: block;
      }

      &.delete {

        .tooltip {
          background-color: $secondary-color;

          &::after {
            border-bottom-color: $secondary-color;
          }
        }
      }

      .tooltip {
        position: absolute;
        top: calc(100% + 5px);
        right: -15px;
        background-color: $primary-color;
        color: $primary-light-color;
        height: 39px;
        border-radius: 3px;
        width: 88px;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 10;

        &::after {
          bottom: 100%;
          right: 20px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba($primary-color, 0);
          border-bottom-color: $primary-color;
          border-width: 6px;
          margin-left: -6px;
        }
      }

      &.delete {
        color: $secondary-color;
      }

      &:hover {

        .tooltip {
          display: flex;
        }
      }
    }

    .icon-button {
      margin-left: 20px;

      &:hover {
        background-color: $primary-light-color;
      }
    }

    .more-actions {
      position: relative;

      .dropdown {
        position: absolute;
        top: calc(100% + 2px);
        right: 0;
        background-color: $primary-light-color;
        width: 120px;
        padding: 10px;
        border-radius: 6px;
        box-shadow: 0 0 10px rgba($shadow-color, 0.2);
        z-index: 10;

        ul {

          li {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            color: $nonary-text-color;
            cursor: pointer;

            svg {
              font-size: 1.125rem;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    min-width: 120px;
    white-space: nowrap;
    background-color: $primary-light-color;
    border-radius: 3px;
    border: 1px solid $senary-border-color;
    padding: 10px 0;
    z-index: 1;

    &::after, &::before {
      bottom: 100%;
      left: 90%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    
    &::after {
      border-color: rgba($primary-light-color, 0);
      border-bottom-color: $primary-light-color;
      border-width: 6px;
      margin-left: -6px;
    }
    &::before {
      border-color: rgba($senary-border-color, 0);
      border-bottom-color: $senary-border-color;
      border-width: 7px;
      margin-left: -7px;
    }

    li {
      font-size: 0.8125rem;
      font-weight: 700;
      color: $nonary-text-color;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      transition: background-color .3s ease, color .3s ease;

      &:hover {
        color: $primary-color;
        background-color: $tertiary-bg-color;

        &.delete {
          background-color: $bg-color-20;
          color: $text-color-22;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.mobile {
        display: none;

        @media (max-width: $break-md-max) {
          display: flex;
        }
      }

      .icon {
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;

        &.rotate-180 {
          transform: rotate(180deg);
        }

        svg {
          font-size: 1.25rem;
        }
      }

      &.edit {

        svg {
          font-size: 1.25rem;
        }
      }
    }
  }
}