@use '../abstracts/variables' as *;

.statuses {
  margin-bottom: 40px;

  h6 {
    margin-bottom: 16px;
    color: $color-12;
    letter-spacing: 1px;
  }

  ul {

    > li {
      display: flex;
      align-items: center;
      color: $color-12;
      font-size: 0.8125rem;
      margin-bottom: 4px;
      padding: 4px 7px 4px 12px;
      cursor: pointer;
      transition: background-color .3s ease;
      width: calc(100% + 19px);
      margin-left: -12px;
      border-radius: 3px;
      border: 1px solid transparent;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover,
      &.active {
        background-color: $primary-light-color;
        border-color: $tertiary-bg-color;
      }

      span {
        background-color: $status-color;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 20px;
        display: block;
      }

      &.ready {

        span {
          background-color: $tertiary-color;
        }
      }

      &.ok,
      &.COMPLETED {

        span {
          background-color: $status-color-2;
        }
      }
      &.draft {

        span {
          background-color: $octonary-color;
        }
      }
      &.in-progress,
      &.attente,
      &.SIGNING_PENDING {

        span {
          background-color: $status-color-3;
        }
      }
      &.closed {

        span {
          background-color: $quinary-color;
        }
      }
      &.expired {

        span {
          background-color: $senary-color;
        }
      }
      &.canceled {

        span {
          background-color: $secondary-color;
        }
      }
      &.validated {

        span {
          background-color: $quinary-color;
        }
      }
      &.to_validate {

        span {
          background-color: $status-dot-color-5;
        }
      }
    }
  }
}