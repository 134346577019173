@use '../abstracts/variables' as *;

.tasks {

  &__item {
    display: flex;
    padding-bottom: 12px;
    border-bottom: 1px solid $secondary-border-color;
    margin-bottom: 12px;
    position: relative;
    min-width: 0;

    .loader-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      background-color: rgba($primary-light-color, 0.8);
      z-index: 5;
      align-items: flex-start;
    }
    
    &_status {
      width: 16px;
      height: 16px;
      border: 1px solid $octonary-border-color;
      border-radius: 50%;
      margin-right: 10px;
      cursor: pointer;
      position: relative;
      transition: border .3s ease;
      
      svg {
        display: block;
        opacity: 0;
        transition: opacity .3s ease;
        font-size: 1.2rem;
        color: $quinary-text-color;
        position: absolute;
        top: -2px;
        left: -2px;
      }

      &:hover {
        border-color: transparent;

        svg {
          opacity: 1;
        }
      }
    }

    &_name {
      flex: 1;
      font-size: 0.8125rem;
      line-height: 1.385;
      color: $primary-dark-color;
      position: relative;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      
      &:hover {
        padding-right: 80px;

        .icons {
          opacity: 1;
        }
      }

      .icons {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        opacity: 0;
        transition: opacity .3s ease;

        a {
          display: block;
          margin-left: 5px;
          color: $secondary-text-color;
          
          svg {
            display: block;
            font-size: 1.25rem;
          }
        }
      }
    }

    &--completed {

      .tasks__item_status {
        border-color: transparent;

        svg {
          opacity: 1;
        }
      }

      .tasks__item_name {
        color: $quinary-text-color;
        text-decoration: line-through;
      }
    }
  }

  &--completed {

    .tasks__item {

      &_status {
        border: 0;
  
        svg {
          display: block;
          font-size: 1.2rem;
          color: $quinary-text-color;
        }
      }

      &_name {
        color: $quinary-text-color;
        text-decoration: line-through;
      }
    }
  }
}

.add-new-task-btn-wrapper {
  margin-bottom: 12px;

  a {
    color: $quinary-text-color;
    font-weight: 500;
    font-size: 0.875rem;
    display: inline-flex;
    align-items: center;

    svg {
      display: block;
      margin-right: 5px;
      font-size: 1.5rem;
      color: $primary-color;
    }
  }
}

.dashboard-box-container--half-width {

  .tasks-v2 {

    &__item_hover {
      background-color: #E7E9EE;
      transition: background-color .3s ease;
      border-left: 1px solid rgba(#07A97A, 0.1);
    }
  }
}

.tasks-v2 {
  margin-bottom: 25px;

  &__item {
    display: flex;
    align-items: center;
    background-color: $dashboard-bg;
    padding: 7px 12px;
    border-radius: 3px;
    margin-bottom: 10px;
    position: relative;
    transition: background-color .3s ease;

    &:last-child {
      margin-bottom: 0;
    }

    &_left {
      display: flex;
      align-items: center;
      min-width: 0;
    }

    &_status {
      width: 14px;
      min-width: 14px;
      height: 14px;
      border: 1px solid $quaternary-border-color;
      border-radius: 50%;
      background-color: $primary-light-color;
      margin-right: 10px;
      position: relative;
      cursor: pointer;

      svg {
        font-size: 1.0625rem;
        position: absolute;
        top: -2px;
        left: -2px;
        color: $senary-color;
      }
    }

    &_name {
      font-size: 0.875rem;
      line-height: 1.286;
      color: $secondary-bg-color;
      cursor: pointer;
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &_assigned-to,
    &_date {
      margin-left: 10px;
      font-size: 0.6875rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 1px 5px;
      border: 1px solid rgba(#07A97A, 0.3);
      border-radius: 2px;
    }

    &_date {
      
      &.current-date {
        border-color: #f2d600;
        background-color: #f2d600;
        color: #fff;
      }
      
      &.already-passed {
        border-color: #eb5a46;
        background-color: #eb5a46;
        color: #fff;
      }

      &.done {
        border-color: #61bd4f;
        background-color: #61bd4f;
        color: #fff;
      }
    }

    &_hover {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      padding: 6px 8px 6px 7px;
      display: flex;
      align-items: center;
      display: none;

      .doc {
        background-color: $primary-light-color;
        color: $primary-color;
        box-shadow: 0 2px 4px 0 rgba($shadow-color-12, 0.5);
        font-size: 0.75rem;
        border-radius: 3px;
        display: flex;
        align-items: center;
        height: 22px;
        padding: 0 7px 0 4px;
        transition: background-color .3s ease;
        max-width: 150px;
        
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          min-width: 0;
        }

        svg {
          font-size: 1.0625rem;
          margin-right: 4px;
        }

        &:hover {
          background-color: darken($primary-light-color, 5);
        }
      }

      .delete {
        color: $nonary-text-color;
        margin-left: 7px;
        transition: color .3s ease;
        
        svg {
          font-size: 1.25rem;
          display: block;
        }

        &:hover {
          color: $text-color-26;
        }
      }
    }

    &:hover {
      background-color: $bg-color-23;

      .tasks-v2__item_hover {
        display: flex;
      }
    }

    &--completed {
      border: 1px solid $quinary-color-light;
      background-color: transparent;

      .tasks-v2__item_name {
        color: $senary-color;
      }
    }
  }
}

.tasks-page {

  .tasks-v2 {

    &__item {
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: $break-xs-max) {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      &_hover {
        position: static;
        display: flex;
        padding-left: 0;
        padding-right: 0;

        @media (max-width: $break-xs-max) {
          width: 100%;
          margin-top: 5px;
          padding-bottom: 0;
        }
      }
    }
  }
}