@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.dashboard-view {
  background-color: $primary-light-color;

  &__head {
    box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
    padding-bottom: 10px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 282px;
    width: calc(100% - 282px);
    z-index: 6;
    background-color: $primary-light-color;
    transition: width .3s ease, left .3s ease;
    
    &.not-fixed {
      position: sticky;
      top: 0;
      width: 100%;
    }

    &.static {
      position: static;
      width: 100%;
    }

    @media (max-width: $break-lg-max) {
      left: 48px;
      width: calc(100% - 48px);
    }

    @media (max-width: $break-md-max) {
      padding-bottom: 0;
    }

    &_top-wrap {
      height: 59px;
      opacity: 1;
      transition: height .3s ease, opacity .3s ease;

      &.shrink {
        height: 0;
        opacity: 0;
        z-index: -1;
      }
    }
    
    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 15px 13px 20px;
      margin-bottom: 12px;
      position: relative;

      h4 {
        display: flex;
        align-items: center;

        span {
          display: none;
          margin-right: 10px;
          cursor: pointer;

          svg {
            display: block;
          }

          @media (max-width: $break-lg-max) {
            display: block;
          }
        }
      }

      .search-trigger-wrapper {
        position: relative;
  
        .close {
          position: absolute;
          left: 100%;
          top: 0;
          width: 20px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          
          svg {
            display: block;
            font-size: 1.25rem;
          }
        }
      }

      @include searchTrigger {
        width: 143px;
      }

      @media (max-width: $break-md-max) {
        margin-bottom: 0;
      }

      @media (max-width: $break-sm-max) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    &_actions {
      padding: 6px 15px 6px 20px;
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 6;

      @media (max-width: $break-md-max) {
        top: 59px;
        z-index: 5;
      }

      .add-btn {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: 0;
        height: 24px;
        padding: 0 0 0 10px;
        font-size: 0.75rem;
        cursor: pointer;
        margin-right: 15px;

        span {
          height: 100%;
          display: block;
          border-left: 1px solid #ccc;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            display: block;
          }
        }
      }
    }

    &_bottom {
      display: flex;
      padding: 12px 15px 0 20px;
      min-height: 32px;

      @media (max-width: $break-md-max) {
        display: none;
      }

      .dashboard-column {
        justify-content: center;
        height: 34px;

        &:first-child {
          justify-content: flex-start;
        }

        &:last-child {
          text-align: right;
          margin-right: 0;
          justify-content: flex-end;
        }

        &.col-1 {
          justify-content: flex-start;
          text-align: left;
          margin-right: 15px;
        }
      }
    }
  }

  &__body {
    padding-top: 115px;

    &--documents-v2,
    &--contacts-v2 {
      padding-top: 59px;
      height: calc(100% - 59px);

      &.no-results {
        height: 150px;
      }
    }

    // &--contacts {
    //   padding-top: 152px;
    // }

    &.no-padding-top {
      padding-top: 0;
    }

    @media (max-width: $break-md-max) {
      padding-top: 59px;
    }

    .dashboard-row {

      .dashboard-column {

        &.col-1 {

          .checkbox {
            display: none;
          }
        }

        &.col-font-small {
          font-size: 0.8125rem;

          span {
            font-weight: 300;
          }
        }
      }

      &:hover,
      &.active {

        .dashboard-column {

          &.col-1 {

            .checkbox {
              display: block;
            }
            .icon.static {
              display: block;
            }
            .icon {
              display: none;
            }
          }

          &__main {

            p.title {
              color: $primary-color;
            }
          }
        }
      }
    }
  }

  &--documents,
  &--contacts {
    display: flex;
    flex-direction: column;
    height: calc(100% - 66px);

    .loader-wrapper {
      padding-top: 100px;
    }

    .dashboard-view__head {
      padding-bottom: 0;
      box-shadow: none;
      // position: relative;
      // width: 100%;
      // left: 0;

      &.shadow {
        box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
      }

      @media (max-width: $break-md-max) {
        box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
      }
    }

    .dashboard-view__head_bottom {
      padding-bottom: 10px;
      position: sticky !important;
      top: 0;
      background-color: #fff;
      z-index: 6;
      display: table-header-group !important;
      transition: box-shadow .3s ease;

      > div {
        display: table-row;
      }
    }

    .dashboard-view__body_table {
      display: table;
      table-layout: fixed;
      width: 100%;

      &.resizable {

        @media (max-width: $break-md-max) {
          min-width: 100% !important;
        }

        .dashboard-view__head_bottom {
          
          .dashboard-column {
      
            &.col-1 {
              max-width: none;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .dashboard-row {

          .dashboard-column {

            &__inner {
              display: block;

              p.title,
              .text-overflow,
              .tags {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.user-col {

                  .user-thumb,
                  .letter-circle {
                    margin: 0 auto;
                  }

                  .user-thumb {
                    width: 30px;
                    height: 30px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    border-radius: 50%;
                  }
                }
              }

              .tags {
                display: block;

                .tag {
                  margin-right: 4px;
                }
              }
            }

            &.actions-col {

              .dashboard-column__inner {
                display: flex;
              }
            }
          }
        }

      }

      &.non-resizable {

        .dashboard-view__head_bottom {
          
          .dashboard-column {
      
            &.col-1 {
              max-width: 430px;
              width: 430px !important;
            }
          }
        }

      }

      .dashboard-view__head_bottom {

        &.with-shadow {
          box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
        }

        .document-checked {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          max-width: calc(100vw - 282px);
          height: calc(100% - 1px);
          background-color: #fff;
          padding: 0 15px 0 20px;
          display: flex;

          @media (max-width: $break-lg-max) {
            max-width: calc(100vw - 48px);
          }
        }

        @media (max-width: $break-md-max) {
          display: none !important;
        }
      }

      .dashboard-view__head_bottom,
      .dashboard-row {
        display: table-row;

        &.footer-row {

          .dashboard-column__inner {
            min-height: 65px;
          }
        }

        @media (max-width: $break-md-max) {
          display: block;

          &.folder-row {

            .col-status_label {
              // display: none;
            }
          }
        }

        .dashboard-column {
          display: table-cell;
          vertical-align: middle;
          border-bottom: 1px solid #e5e5e5;

          &.checkbox_col-col {

            .dashboard-column__inner {
              padding-left: 33px !important;
            }
          }

          &.col-1,
          &.col-2,
          &.col-3,
          &.col-4 {
            width: auto;
          }

          &__inner-wrapper {
            min-height: 65px;
            display: flex;
            align-items: center;
          }

          &__inner {
            display: flex;
            padding: 15px;
            justify-content: center;
            text-align: center;
            flex: 1;
          }

          &.col-1 {

            .dashboard-column__inner {
              justify-content: flex-start;
              text-align: left;
            }
          }

          &.custom-col {
            font-size: 0.75rem;
            color: #172B4D;
          }

          &:first-child {

            .dashboard-column__inner {
              padding-left: 20px;

              .checkbox,
              .icon {
                left: 20px;
              }
            }
          }

          @media (max-width: $break-md-max) {
            display: block;
            border-bottom: 0;

            &__inner-wrapper {
              display: block;
              min-height: 1px;
            }
            
            &__inner {
              padding: 0;
              justify-content: flex-start;
              text-align: left;
            }

            &:first-child {

              .dashboard-column__inner {
                padding-left: 0;
  
                .checkbox,
                .icon {
                  left: 0;
                }
              }
            }
          }
        }

        .more .dropdown {
          top: calc(50% + 24px);
        }
      }
    }
  }

  &--contacts {

    .dashboard-view__body_table .dashboard-view__head_bottom .dashboard-column__inner {
      padding: 15px 25px 15px 10px;
    }
  }
}

.collection-hidden-fields {

  > div {

    .checkbox {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        align-items: center;
        cursor: pointer;
      }

      &--disabled {

        .checkbox__btn,
        .checkbox__label {
          color: #c3c2c2;
        }
      }
    }
  }
}