@use '../abstracts/variables.scss' as *;

.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;

  @media (min-width: $break-lg-min) {
    display: none;
  }

  .mobile-menu-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .mobile-menu {
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 1;
    background-color: #fff;
    border-radius: 20px;
    padding: 40px;
    width: 90%;
    height: 90%;
    overflow-y: auto;
    box-shadow: 0 0 10px rgba(#000, 0.15);

    &__close {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #10C0B5;
      cursor: pointer;
    }

    section {
      margin-bottom: 30px;

      h3 {
        margin-bottom: 20px;
        font-weight: 500;
      }
    }

    &__content {
      display: flex;

      ul {
        min-width: 50%;

        li {
          margin-bottom: 10px;
          color: #0E1E40;

          a {
            color: inherit;
          }
        }
      }

      ul.features-list {
        display: flex;
        flex-wrap: wrap;

        li {
          width: calc(50% - 10px);
          margin-right: 10px;
          margin-bottom: 15px;

          a {
            white-space: normal;
            display: flex;
            align-items: center;

            span {

              &.icon {
                width: 30px;
                min-width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba($icon-box-circle-bg, 0.44);
                border-radius: 50%;
                overflow: hidden;
                margin-right: 10px;

                span {
                  font-size: 1.25rem;
                  color: $icon-box-circle-text;
                }
              }
            }
          }
        }
      }
    }
  }
}