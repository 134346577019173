@use '../abstracts/variables' as *;

.landing-modeling-documents {
  position: relative;

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 57px;
    position: relative;

    > div {
      box-shadow: 0 20px 33px 0 rgba($shadow-color-7, 0.2);

      &:nth-child(2),
      &:nth-child(3) {
        margin-left: -107px;
      }
    }

    img {
      display: block;
      // box-shadow: 0 20px 33px 0 rgba($shadow-color-7, 0.2);
      
      &:nth-child(2),
      &:nth-child(3) {
        margin-left: -107px;
      }

      @media (max-width: $break-xs-max) { 
        max-width: 140px;

        &:nth-child(2),
        &:nth-child(3) {
          margin-left: -75px;
        }
      }
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(0,0,0);
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
      content: '';
    }

    &::before {
      position: absolute;
      bottom: -53px;
      height: 53px;
      width: 100%;
      background-color: $primary-light-color;
      left: 0;
      content: '';
      z-index: 2;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__inner {
    display: flex;
    padding-top: 104px;

    @media (max-width: $break-md-max) {
      display: block;
    }

    .landing-label {
      margin-bottom: 23px;
    }

    h2 {
      margin-bottom: 25px;
      color: #144CB1;
      word-wrap: break-word;
    }

    p {
      color: #144CB1;
      margin-bottom: 73px;
    }

    &_left {
      width: 50%;
      padding-right: 70px;
      position: relative;

      .border-right {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 1px;
        height: calc(100% + 104px);
        background: rgb(255,255,255);
        background: linear-gradient(152deg, rgba(255,255,255,0.61) 0%, rgba(235,235,235,1) 33%, rgba(212,212,212,1) 100%);
        z-index: 1;
      }

      .word-document {
        margin-bottom: -42px;
        margin-left: 20px;
      }

      @media (max-width: $break-md-max) {
        width: 100%;
        margin-bottom: 40px;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 40px;

        p {
          margin-bottom: 40px;
          text-align: center;
        }

        h2 {
          text-align: center;
        }

        .word-document {
          margin-bottom: 0;
        }

        .border-right {
          width: 100%;
          height: 1px;
          right: auto;
          left: 0;
        }
      }
    }

    &_right {
      width: 50%;
      padding-left: 88px;
      position: relative;

      img {
        margin-bottom: -60px;
        max-width: none;
      }

      @media (max-width: 1100px) {
        img {
          max-width: 100%;
        }
      }

      @media (max-width: $break-md-max) {
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        p,
        h2 {
          text-align: center;
        }
      }
    }
  }

  &__bgs {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 492px;
    z-index: -1;
    overflow: hidden;

    @media (max-width: $break-md-max) {
      display: none;
    }

    .bg-1,
    .bg-2 {
      position: absolute;
      bottom: -70px;
      width: 50%;
      height: 562px;
    }

    .bg-1 {
      left: 0;
      background: rgb(255,255,255);
      background: linear-gradient(176deg, rgba(255,255,255,0.61) 0%, rgba(225,237,246,1) 100%);
    }

    .bg-2 {
      left: 50%;
      background: rgb(255,255,255);
      background: linear-gradient(174deg, rgba(255,255,255,0.61) 0%, rgba(240,240,240,1) 100%);
    }
  }

  .modeling-label {
    font-family: $ff-tertiary-bold;
    display: block;
    margin: 0 auto 45px;
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    background-color: #3186e1;
    background-image: linear-gradient(175deg, #3186e1, #0f459d);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    white-space: pre-wrap;
  }
}
