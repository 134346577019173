@use '../abstracts/variables.scss' as *;

.menu-root {
  z-index: 10;
  position: absolute;

  @media (max-width: $break-md-max) {
    display: none;
  }
}

.menu-arrow {
  position: relative;
  top: 20px;
  
  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: #fff;
    top: -6px;
    left: -6px;
    border-radius: 2px 0 0 0;
    transform: rotate(45deg);
  }
}

.menu-container {
  position: absolute;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(#000, 0.2);
  background: #fff;
  border-radius: 4px;
  top: 20px;
}

.menu-section {
  position: absolute;
}

.menu-background {
  position: absolute;
  bottom: 0;
  background: #f6f9fc;
  width: 100%;
}

.animated-menu-content {
  display: flex;
  white-space: nowrap;
  padding: 20px;

  ul {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: #0E1E40;
      }
    }

    &.features-list {
      width: 500px;
      display: flex;
      flex-wrap: wrap;

      li {
        width: calc(50% - 10px);
        margin-right: 10px;
        margin-bottom: 15px;

        a {
          white-space: normal;
          font-size: 0.875rem;
          display: flex;
          align-items: center;

          span {

            &.icon {
              width: 30px;
              min-width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba($icon-box-circle-bg, 0.44);
              border-radius: 50%;
              overflow: hidden;
              margin-right: 10px;

              span {
                font-size: 1.25rem;
                color: $icon-box-circle-text;
              }
            }
          }
        }
      }
    }
  }
}