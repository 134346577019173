@use '../abstracts/variables' as *;

.landing-intro {
  padding: 81px 0 250px;
  position: relative;
  overflow: hidden;

  @media (max-width: 920px) {
    padding: 40px 0;
  }

  @media (max-width: $break-md-max) {
    display: block;
  }

  &__left {
    flex: 1;
    max-width: 560px;

    @media (min-width: 1231px) {
      max-width: 720px;
    }

    @media (max-width: 1230px) {
      max-width: 600px;
    }

    @media (max-width: 1070px) {
      max-width: 560px;
    }

    @media (max-width: $break-lg-max) {
      padding-right: 30px;
    }

    @media (max-width: 920px) {
      max-width: 100%;
    }
    
    @media (max-width: $break-md-max) {
      margin-bottom: 50px;
      padding: 0;
    }

    h1 {
      font-family: $ff-tertiary-bold;
      font-size: 3.25rem;
      font-weight: 700;
      line-height: 1;
      color: $denary-dark-color;
      margin-bottom: 38px;

      @media (max-width: $break-xxs-max) {
        font-size: 2.75rem;
      }
    }

    p {
      font-family: $ff-tertiary;
      font-size: 1rem;
      line-height: 1.375;
      color: $text-color-23;
      margin-bottom: 31px;
      max-width: 475px;

      @media (min-width: $break-lg-min) {
        max-width: 620px;
      }

      @media (max-width: 920px) {
        max-width: 100%;
      }

      &.subtitle {
        font-size: 1.0625rem;
        line-height: 1.294;
        color: $denary-dark-color;
        letter-spacing: 0.2px;
        margin-bottom: 22px;
      }
    }

    ul {
      padding-left: 11px;

      li {
        font-family: $ff-tertiary;
        display: flex;
        align-items: center;
        color: $octonary-dark-color;
        font-size: 0.75rem;
        letter-spacing: 0.6px;
        margin-bottom: 5px;

        svg {
          display: block;
          color: $check-color;
          font-size: 1.125rem;
          margin-right: 7px;
        }
      }
    }
  }

  &__right {
    margin-left: auto;
  }

  &__actions {
    margin-bottom: 17px;
    max-width: 560px;

    @media (max-width: $break-lg-max) {

      .button {

        &:first-child {
          margin-bottom: 15px;
        }
      }
    }

    .button {
      max-width: 324px;
      // width: 100%;

      &:first-child {
        margin-bottom: 15px;
      }
    }
  }

  .intro-images {
    position: absolute;
    top: 105px;
    left: 58.5%;
    max-width: 100%;
    z-index: -1;

    img {
      display: block;
      max-width: 100%;
    }

    @media (max-width: 920px) {
      display: none;
    }
  }

  img.dashboard-preview {
    position: absolute;
    top: 88px;
    left: 58.5%;
    max-width: 100%;
    z-index: -1;
    border-radius: 10px;
    box-shadow: 0 20px 33px 0 rgba($shadow-color-7, 0.5);

    @media (max-width: 920px) {
      display: none;
    }
    // @media (max-width: $break-md-max) {
    //   max-width: 25%;
    //   top: 40px;
    // }
  }

  &--dpo {
    padding-bottom: 135px;

    @media (max-width: 920px) {
      padding-bottom: 40px;
    }
    
    h1 {
      font-size: 2.375rem;
      line-height: 1.21;
      color: #0E1E40;
      margin-bottom: 20px;

      span {
        color: #FF9A5B;
      }
    }

    p {
      margin-bottom: 14px;

      &.bold {
        font-size: 1.0625rem;
        line-height: 1.29;
        letter-spacing: 0.2px;
        font-family: $ff-tertiary-medium;
        color: #0E1E40;
        font-weight: 500;
      }

      &.regular {
        font-size: 1rem;
        line-height: 1.625;
        color: #5D606B;

        span {
          font-family: $ff-tertiary-medium;
          color: #0E1E40;
          font-weight: 500;
        }
      }
    }

    .btn {
      margin-top: 20px;
    }
  }
}