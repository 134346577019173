@use '../abstracts/variables' as *;

.landing-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 129px;
  max-width: 346px;

  @media (max-width: $break-lg-max) {
    margin-top: 40px;
  }

  .tag {
    display: inline-flex;
    align-items: center;
    height: 32px;
    padding: 0 5px 0 14px;
    background: rgba(252,182,159,0.5);
    background: linear-gradient(270deg, rgba(252,182,159,0.5) 0%, rgba(255,236,210,0.5) 100%);
    border-radius: 16px;
    margin-right: 12px;
    margin-bottom: 12px;
    
    .text {
      font-family: $ff-quaternary;
      font-size: 0.6875rem;
      line-height: 1.63;
      font-weight: 700;
      letter-spacing: 0.6px;
      color: $text-color-12;
      text-transform: uppercase;
    }
    
    .num {
      width: 21px;
      height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-light-color;
      border-radius: 50%;
      color: $text-color-13;
      font-size: 0.6875rem;
      font-family: $ff-secondary;
      font-weight: 900;
      margin-left: 6px;
    }
  }
}