@use '../abstracts/variables' as *;

.help-categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__box {
    width: 32%;
    margin: 0 0.666666666667% 20px;
    height: 120px;
    border: 1px solid #e6e9ee;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    background-color: #fff;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color .3s ease;

    @media (max-width: $break-md-max) {
      width: 48%;
      margin: 0 1% 20px;
    }

    @media (max-width: $break-sm-max) {
      width: 100%;
      margin: 0 0 20px;
    }

    &:hover {
      background-color: #e6e9ee;
    }

    h3 {
      font-weight: 400;
      margin-bottom: 10px;
      letter-spacing: 0;
      font-size: 1.125rem;
      line-height: 1.2;
      text-align: center;
    }

    p {
      font-size: 0.8125rem;
      color: #5D6C85;
    }
  }
}