@use '../abstracts/variables' as *;

.dashboard-side-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  
  &.active {

    .dashboard-side-panel__overlay {
      opacity: 1;
    }

    .dashboard-side-panel__inner {
      right: 0;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary-dark-color, 0.6);
    opacity: 0;
    transition: opacity .3s ease;
  }

  &__inner {
    position: fixed;
    top: 0;
    right: -336px;
    width: 336px;
    height: 100%;
    background-color: $primary-light-color;
    padding: 15px;
    overflow-y: auto;
    transition: right .3s ease;

    &_head {
      background-color: $septenary-border-color;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      margin-bottom: 24px;

      h5 {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: 500;
        color: $tertiary-dark-color;
        margin-left: 10px;
        letter-spacing: 1px;
      }

      svg {
        font-size: 1rem;
        display: block;
      }
    }

    &_body {

      p {
        font-size: 0.875rem;
        font-weight: 700;
        margin-bottom: 20px;
      }

      ul {

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          font-size: 0.875rem;
          margin-bottom: 10px;
          border-bottom: 1px solid $primary-border-color;
          padding-bottom: 10px;

          &:last-child {
            border-bottom: 0;
          }

          span {
            margin-right: 10px;
            display: block;
          }
        }
      }
    }
  }
}