@use '../abstracts/variables' as *;

.landing-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
  
  &--analysis-section {
    border-bottom: 1px solid $border-color-11;

    @media (max-width: $break-lg-max) {
      padding: 0;
    }
  }
}