@use '../abstracts/variables' as *;

.landing-logo {
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 32px;
    height: 32px;
    margin-right: 7px;
    transition: all .3s ease;
  }

  p {
    font-family: $ff-tertiary-bold;
    font-size: 1.5625rem;
    color: $tertiary-dark-color;
    transition: font-size .3s ease;
    letter-spacing: 0.42px;

    @media (max-width: 400px) {
      display: none;
    }
  }

  &--light {

    p {
      color: $primary-light-color;
    }
  }
}