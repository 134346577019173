@use '../abstracts/variables' as *;

.landing-label {

  span {
    display: inline-block;
    text-align: center;
    padding: 3px 22px;
    border-radius: 17.5px;
    font-family: $ff-tertiary-bold;
    letter-spacing: 0.74px;
    font-size: 0.75rem;
    line-height: 2.16;
    text-transform: uppercase;  
  }

  &--sm {

    span {
      font-size: 0.6875rem;
      padding: 3px 14px;
    }
  }

  &--1 {

    span {
      background: rgb(241,253,248);
      background: linear-gradient(180deg, rgba(241,253,248,1) 0%, rgba(222,249,239,1) 100%);
      color: #16B125;
    }
  }

  &--2 {

    span {
      background: #FFF3F3;
      background: linear-gradient(180deg, #FFF3F3 0%, #FFDFD4 100%);
      color: #D14D0F;
    }
  }

  &--3 {

    span {
      background: rgb(255,118,118);
      background: linear-gradient(0deg, rgba(255,118,118,1) 0%, rgba(245,78,162,1) 100%);
      color: $primary-light-color;
    }
  }

  &--4 {

    span {
      background: rgba(43, 124, 211, 0.5);
      color: $primary-light-color;
    }
  }

  &--5 {

    span {
      background: #dadfe6;
      color: $primary-light-color;
    }
  }

  &--6 {

    span {
      background: rgb(185,255,200);
      background: linear-gradient(180deg, rgba(185,255,200,1) 0%, rgba(186,255,201,1) 100%);
      color: #03A214;
    }
  }

  &--7 {

    span {
      background-color: rgba(#5FB7F2, 0.55);
      color: $primary-light-color;
    }
  }

  &--8 {

    span {
      background-color: #DADFE6;
      color: #2A2C30;
    }
  }

  &--9 {

    span {
      background-color: #E7ECEF;
      color: #7B7D80;
    }
  }

  &--10 {

    span {
      background: rgb(128,191,255);
      background: linear-gradient(180deg, rgba(128,191,255,1) 0%, rgba(59,148,239,1) 100%);
      color: $primary-light-color;
    }
  }

  &--11 {

    span {
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(217,217,217,1) 100%);
      color: #3A3636;
    }
  }
}