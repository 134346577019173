@use '../abstracts/variables.scss' as *;

.single-article {

  &--flex {
    display: flex;

    @media (max-width: $break-md-max) {
      flex-direction: column;
    }
  }

  aside {
    width: 200px;
    margin-right: 40px;

    @media (max-width: $break-md-max) {
      width: 100%;
      margin-bottom: 40px;
      margin-right: 0;

      form {
        max-width: 100%;
      }
    }

    h4 {
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 20px;
      cursor: pointer;
    }

    ul {

      li {
        font-size: 0.875rem;
        margin-bottom: 5px;

        a {
          color: #5D6C85;
          display: flex;
          align-items: center;
          line-height: 24px;

          svg {
            display: none;
          }

          &:hover {
            color: darken(#5D6C85, 10);
          }
        }

        &.active {

          a {
            font-weight: 700;

            &:hover {
              color: #5D6C85;
            }

            svg {
              display: block;
            }
          }
        }
      }
    }

    .back {
      color: #5D6C85;
      display: flex;
      align-items: center;
      margin-top: 30px;
      font-size: 0.875rem;
      border-top: 1px solid #e6e9ee;
      padding-top: 10px;

      &:hover {
        color: #000;
      }
    }
  }

  main {
    flex: 1;
  }

  &__no-data {
    border: 1px solid #e6e9ee;
    background-color: #F5F6F8;
    padding: 50px 20px;

    p {
      font-size: 1rem;
      text-align: center;
    }
  }
}