@use '../abstracts/variables' as *;

.landing-footer-wrapper {
  padding: 24px 0;
  background-color: $bg-color-16;
}

.landing-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $break-lg-max) {
    flex-direction: column;
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      margin: 0 32px;

      a {
        display: block;
        color: $text-color-18;
        font-size: 0.75rem;
        font-family: $ff-tertiary;
        letter-spacing: 1px;
        transition: color .3s ease;

        &:hover {
          color: $primary-light-color;
        }
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;

    @media (max-width: $break-lg-max) {
      margin-bottom: 30px;
    }

    a {
      display: block;

      &:first-child {
        margin-right: 17px;
      }

      svg {
        width: 24px;
        height: auto;
        display: block;
      }

      &.twitter {

        svg {
          width: 30px;
        }
      }
    }
  }

  .by {
    font-size: 0.75rem;
    font-family: $ff-tertiary-medium;
    letter-spacing: 1px;

    a {
      color: #74E5FF;
    }
  }

  @media (max-width: $break-lg-max) {
    .landing-logo {
      justify-content: center;
    }

    &__links {
      margin: 30px 0;
    }
  }

  @media (max-width: $break-md-max) {
    &__links {
      
      li {
        margin: 0 16px;
      }
    }
  }

  @media (max-width: $break-sm-max) {
    &__links {
      flex-direction: column;
      justify-content: center;
      
      li {
        margin: 0 0 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}