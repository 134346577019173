@use '../../abstracts/variables.scss' as *;

.article-wrapper {
  width: 48%;
  margin-bottom: 40px;
  
  &:hover {

    .article {
      top: -15px;
    }
  }

  @media (max-width: $break-sm-max) {
    width: 100%;

    &:hover {

      .article {
        top: 0;
      }
    }
  }

  a {
    display: block;
    height: 100%;
  }
}

.article {
  background-color: #fff;
  border-radius: 4px;
  color: #0E1E40;
  position: relative;
  top: 0;
  transition: top .15s ease;
  height: 100%;

  &__featured_img {
    position: relative;
    height: 300px;
    overflow: hidden;
    border-radius: 4px 4px 0 0;

    img {
      max-width: none;
      max-height: 300px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__info {
    padding: 40px;
  }

  &__category {
    margin-bottom: 15px;

    span {
      color: #19C6E6;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: 700;
    }
  }

  &__title {
    margin-bottom: 15px;

    h2 {
      font-weight: 500;
    }
  }

  &__desc {

    p {
      font-size: 0.875rem;
      line-height: 1.5;
    }
  }

  &__date {
    font-size: 0.75rem;
    margin-bottom: 20px;
    color: #aaa;
  }

  &__content {

    p {
      font-size: 0.875rem;
      line-height: 1.5;
      margin-bottom: 20px;
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto 20px;
    }

    .video-wrapper {
      max-width: 640px;
      margin: 0 auto 20px;
      
      > div > div {
        width: auto !important;
        height: auto !important;
      }

      > div > div > div {
        padding-top: calc(360/640 * 100%);
        position: relative;
        width: auto !important;
        height: auto !important;
        
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    iframe {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}