@use '../abstracts/variables' as *;

.side-menu {
  width: 48px;
  background-color: $color-25;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1002;
  display: flex;
  flex-direction: column;

  &__bg {
    position: fixed;
    width: calc(100vw - 282px);
    height: 100vh;
    left: 282px;
    top: 0;
    background-color: rgba($secondary-bg-color, 0.5);
    display: none;
  }

  &--long {
    width: 282px;
    left: -282px;
    z-index: 1001;
    transition: left .3s ease;

    &.active {
      left: 0;
      z-index: 1003;

      .side-menu__bg {
        display: block;
      }
    }

    .side-menu__list {

      li {

        a {
          justify-content: flex-start;

          &:hover,
          &.active {

            &::after {
              display: none;
            }
          }
        }

        &:first-child {
          display: flex;
          align-items: center;

          a {
            width: 52px;
            margin-right: 14px;

            .icon {
              margin-right: 0;
            }
          }

          .menu-team {
            position: relative;
            flex: 1;

            > div > p {
              display: flex;
              align-items: center;
              color: $color-26;
              font-weight: 500;
              cursor: pointer;
              transition: color .3s ease;
              
              &:hover {
                color: $primary-light-color;
              }
            }

            .team-dropdown {
              width: 280px;
              padding: 0;

              @media (max-width: $break-xxs-max) {
                width: 240px;
              }

              &::after {
                left: 20px;
                width: 8px;
                height: 8px;
                top: -4px;
                display: none;
              }

              &__head {
                padding: 18px;
                display: flex;
                align-items: center;

                &_image-box {
                  width: 44px;
                  height: 44px;
                  background-color: $senary-border-color;
                  border-radius: 3px;
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  margin-right: 15px;
                }

                .letter-circle {
                  width: 44px;
                  height: 44px;
                  border-radius: 3px;
                  margin-right: 15px;
                  font-size: 1.25rem;
                }

                &_info-box {
                  padding-top: 3px;

                  h5 {
                    font-size: 0.9375rem;
                    font-weight: 500;
                    margin-bottom: 3px;
                  }

                  p {
                    font-size: 0.8125rem;
                    font-weight: 400;
                    color: $nonary-text-color;
                  }
                }
              }

              &__body {
                border-top: 1px solid $senary-border-color;
                border-bottom: 1px solid $senary-border-color;
                padding: 16px 0;

                &--no-border-top {
                  border-top: 0;
                }
              }

              &__foot {
                padding: 16px 0;
              }

              &__link-item {
                display: flex;
                align-items: center;
                padding: 4px 20px;
                margin-bottom: 6px;
                cursor: pointer;
                color: $primary-dark-color;
                transition: background-color .3s ease;
                position: relative;

                &:hover {
                  background-color: $primary-bg-color;
                }

                &:last-child {
                  margin-bottom: 0;
                }

                svg {
                  display: block;
                  margin-right: 17px;
                }

                i {
                  font-size: 1.125rem;
                  transform: rotate(90deg);
                  position: relative;
                  left: 3px;
                  margin-right: 20px;
                }

                p {
                  font-size: 0.875rem;
                  font-weight: 400;
                  color: $secondary-bg-color;
                }

                .icon-right {
                  margin-left: auto;
                }

                .inner-dropdown {
                  position: absolute;
                  left: 100%;
                  top: -16px;
                  width: 240px;
                  padding: 22px 29px;
                  background-color: $primary-light-color;
                  cursor: default;
                  border-radius: 3px;
                  box-shadow: 0 4px 10px 0 rgba($primary-dark-color, 0.48);

                  @media (max-width: $break-sm-max) {
                    top: 100%;
                    left: 20px;
                    z-index: 1;
                  }

                  &__title {
                    color: $nonary-text-color;
                    font-size: 0.875rem;
                    line-height: 1.142;
                  }

                  &__list {
                    margin-top: 13px;

                    li {
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                      cursor: pointer;

                      &:last-child {
                        margin-bottom: 0;
                      }

                      .logo-box {
                        width: 24px;
                        height: 24px;
                        border-radius: 3px;
                        background-color: $senary-border-color;
                        margin-right: 10px;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                      }

                      p {
                        font-size: 0.875rem;
                        color: $secondary-bg-color;
                      }
                    }
                  }
                }

                &.disabled {

                  p, svg {
                    color: #ccc;
                    user-select: none;
                  }

                  &:hover {
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }

    .side-menu__bottom {
      justify-content: flex-start;

      a {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;

        .icon {
          width: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }
      }
    }
  }

  &__list {

    li {
      position: relative;

      &:first-child {

        a {
          background-color: $color-25;
          color: $color-26;
          transition: color .3s ease;

          .logo {
            width: 30px;
            height: 30px;
            background-color: $primary-color;
            color: $primary-light-color;
            border-radius: 2px;
            font-size: 0.875rem;
            font-weight: 900;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .thumb {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: $senary-bg-color;
          }

          .letter-circle {
            width: 24px;
            height: 24px;
          }
        }
      }
      
      a {
        display: block;
        color: $color-26;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color .3s ease;

        svg {
          font-size: 1.5rem;
        }

        i {
          font-size: 1.5rem;
          transition: color .3s ease;
        }

        &.active,
        &:hover {
          color: $primary-light-color;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            transform: translate(-50%, -50%);
            z-index: -1;
            background-color: $color-27;
            border-radius: 8px;
          }
        }

        .icon {
          width: 52px;
          height: 100%;
          text-align: center;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.documents {

          svg {
            font-size: 1.75rem;
          }
        }
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    a {
      color: $color-26;
      transition: color .3s ease;
      position: relative;
      width: 100%;
      text-align: center;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;

      .image-box {
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $senary-bg-color;
      }

      &.active {
        color: $primary-light-color;
      }

      &:hover {
        color: $primary-light-color;
      }

      svg {
        font-size: 1.5rem;
      }

      &.logout {

        i {
          transform: rotate(-90deg);
        }
      }
    }
  }
}