@use '../abstracts/variables' as *;

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.iframe {
  width: 100%;
  height: calc(100vh - 170px);
  border: 0;
}

.buttons-wrapper {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    margin: 0 15px;
  }
}

.loader-wrapper,
.preview-error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  &--no-padding {
    padding: 0;
  }
}

.hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.draggable-ghost-el {
  font-size: 1rem;
  padding: 10px 20px;
  display: block;
  position: absolute;
  top: -1000px;
  left: 0;
  background-color: rgba($primary-color, 1);
  color: $primary-light-color;
  box-shadow: 0 0 100px rgba($shadow-color, 0.9);
  display: flex;
  align-items: center;
  min-width: 120px;
  max-width: 300px;
  width: auto;
  cursor: all-scroll;
}

.no-data-to-show {
  text-align: center;
  padding: 50px 0;
}

.back-to-top {
  font-size: 1.5rem;
  color: rgba($primary-dark-color, 0.2);
  cursor: pointer;
  transition: color .3s ease, opacity .3s ease;
  z-index: -1;
  position: relative;
  left: -9999px;
  opacity: 0;

  &.active {
    z-index: 1;
    opacity: 1;
    left: 0;
  }

  &--2 {
    margin-bottom: 20px;
  }

  &:hover {
    color: $primary-color;
  }
}

.u-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .checkbox {
    margin-right: 5px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.charts-container {

  &--pie {
    width: 250px;
    margin: 0 auto;
    overflow: hidden;
  }
}

.u-position {

  &--relative {
    position: relative;
  }
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  p {
    font-size: 0.875rem;
    margin-left: 5px;
  }

  .button {
    margin-left: 20px;
  }
}

.signature-positions {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  .form-input {
    margin-right: 20px;
    margin-bottom: 0 !important;
    width: 100px;
    flex: 0 1 auto !important;
  }

  .button {
    margin-left: auto;
    align-self: flex-end;
  }
}

.fields-wrapper {
  margin: 20px 0 40px;

  > .button {
    margin-bottom: 20px;
  }
}

.sortable-wrapper {
  padding: 20px 0 0;
}

.sortable-list-item {
  background-color: $primary-light-color;
  border: 1px solid $primary-border-color;
  padding: 8px 13px;
  border-radius: 3px;
  cursor: grab;
  margin-bottom: 10px;
  font-size: 0.875rem;
  user-select: none;
}

.field-positions {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  position: relative;

  .form-input {
    margin-right: 20px;
    margin-bottom: 0 !important;
    width: 100px;
    flex: 0 1 auto !important;

    &.label-input {
      width: 200px;
      margin-right: 10px;
    }
  }

  .checkbox-wrapper {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .button {

    &.label-btn {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: auto;
    }
  }

  &__error {
    padding-top: 5px;
    font-size: 0.75rem;
    color: $secondary-color;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.u-cursor--pointer {
  cursor: pointer;
}

.u-cursor--default {
  cursor: default !important;
}

.u-white-space--nowrap {
  white-space: nowrap;
}

.status-el {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5625rem;
  line-height: 1.22;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: $nonary-text-color;
  padding: 5px 8px;
  border-radius: 9px;
  background-color: $tertiary-bg-color;
  text-transform: uppercase;
}

.progress-el {
  width: 100px;
  height: 4px;
  border-radius: 3px;
  background-color: $tertiary-bg-color;
  position: relative;

  &__inner {
    background-color: $octonary-color;
    border-radius: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.link-wrapper {
  margin-bottom: 20px;

  a {
    display: inline-flex;
    align-items: center;
    color: #19C6E6;
    border-bottom: 1px solid transparent;
    flex-wrap: wrap;
    white-space: normal;

    svg {
      margin-right: 2px;
      font-size: 1rem;
    }
  
    &:hover {
      border-bottom: 1px solid #19C6E6;
    }
  }
}

.u-custom-scrollbar {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 rgba(0,0,0,0.06);
  // -webkit-transform: translate3d(0, 0, 0); // react-beautiful-dnd didn't work correctly when item was dragged and this was on

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    background: transparent;
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    background: rgba(0,0,0,0.06);
  }
  &::-webkit-scrollbar-track-piece:vertical:start {
    border-radius: 4px 4px 0 0;
  }
  &::-webkit-scrollbar-track-piece:vertical:end {
    border-radius: 0 0 4px 4px;
  }
  &::-webkit-scrollbar-track-piece:horizontal:start {
    border-radius: 4px 0 0 4px;
  }
  &::-webkit-scrollbar-track-piece:horizontal:end {
    border-radius: 0 4px 4px 0;
  }
  &::-webkit-scrollbar-thumb:vertical,
  &::-webkit-scrollbar-thumb:horizontal {
    background: #c4c4c4;
    border-radius: 4px;
    display: block;
    height: 48px;
  }
}

.animation-box {
  backface-visibility: hidden;
}