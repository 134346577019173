@use '../abstracts/variables' as *;

.landing-analysis-section-wrapper {
  position: relative;
  overflow: hidden;
}

.landing-analysis-section {
  position: relative;
  display: flex;

  @media (max-width: $break-lg-max) {
    display: block;
  }

  &__col {
    flex: 1;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    padding-top: 107px;
    position: relative;
    z-index: 1;

    &:first-child {
      padding-right: 86px;
      position: relative;
      
      &::after {
        content: '';
        width: 2px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(235,235,235,1) 16%, rgba(235,235,235,1) 100%);
      }
    }

    &:last-child {
      padding-left: 86px;
    }

    @media (max-width: $break-lg-max) {
      width: 100%;

      &:first-child {
        padding: 50px 40px;
        border-bottom: 1px solid $border-color-12;
        border-right: 0;
        background: rgb(247,255,251);
        background: linear-gradient(180deg, rgba(247,255,251,0.21) 0%, rgba(183,244,214,0.45) 100%);
      }
  
      &:last-child {
        padding: 50px 40px;
        background: rgba(255,252,248,0.25);
        background: linear-gradient(180deg, rgba(255,252,248,0.25) 0%, rgba(250,239,223,0.55) 100%);
      }
    }

    .landing-label {
      margin-bottom: 27px;
    }

    h2 {
      margin-bottom: 31px;
      color: $text-color-11;
    }

    &_text {

      @media (max-width: $break-lg-max) {
        max-width: 100%;
      }

      p {
        font-size: 1rem;
        font-family: $ff-tertiary;
        margin-bottom: 21px;
        line-height: 1.3125;
        color: $septenary-dark-color;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .tags {
      padding-top: 31px;

      @media (max-width: $break-sm-max) {
        padding-top: 0;
      }
    }

    &_chart {
      margin-top: 118px;
      max-width: 368px;
      display: flex;
      height: 263px;
      border: 1px dashed $senary-border-color;

      @media (max-width: $break-lg-max) {
        margin-top: 40px;
      }

      &-col {
        flex: 1;
        border-right: 1px dashed $senary-border-color;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;

        &:last-child {
          border-right: 0;
        }

        &-line {
          border-bottom: 1px dashed $senary-border-color;
          position: absolute;
          top: 29px;
          left: 0;
          width: 100%;

          &:nth-child(2) {
            top: 58px;
          }
          &:nth-child(3) {
            top: 87px;
          }
          &:nth-child(4) {
            top: 116px;
          }
          &:nth-child(5) {
            top: 145px;
          }
          &:nth-child(6) {
            top: 174px;
          }
          &:nth-child(7) {
            top: 203px;
          }
          &:nth-child(8) {
            top: 232px;
          }
        }

        &-rec {
          width: 100%;
          height: 200px;
          background-color: $chart-bg-color;
          border-radius: 2px;
          position: relative;

          div {
            background: $chart-bg-gradient-color-1;
            background: linear-gradient(135deg, rgba($chart-bg-gradient-color-1,1) 0%, rgba($chart-bg-gradient-color-2,1) 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }

  &__bg-1 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: rgb(247,255,251);
    background: linear-gradient(180deg, rgba(247,255,251,0.21) 0%, rgba(183,244,214,0.45) 100%);

    @media (max-width: $break-lg-max) {
      display: none;
    }
  }

  &__bg-2 {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: rgba(255,252,248,0.25);
    background: linear-gradient(180deg, rgba(255,252,248,0.25) 0%, rgba(250,239,223,0.55) 100%);

    @media (max-width: $break-lg-max) {
      display: none;
    }
  }
}