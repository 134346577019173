@use '../abstracts/variables' as *;

.landing-your-documents {
  padding: 74px 0 55px;
  background: rgb(49,131,215);
  background: linear-gradient(180deg, rgba(49,131,215,1) 0%, rgba(9,44,106,1) 100%);
  overflow: hidden;

  &__top-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .landing-container {

    .landing-label {
      text-align: center;
      margin-bottom: 23px;
    }

    h2 {
      color: $primary-light-color;
      text-align: center;
      margin-bottom: 17px;
    }

    p {
      max-width: 589px;
      margin: 0 auto;
      text-align: center;
      color: #CAE2FF;
    }
  }

  &__boxes {
    display: flex;
    justify-content: center;
    max-width: 660px;
    margin: 47px auto 0;

    &_col {
      width: 111px;
      margin: 0 25px;

      img {
        display: block;
        margin: 0 auto;
      }

      @media (max-width: $break-md-max) {
        width: 100px;
        margin: 0 8px;

        img {
          max-width: 50%;
        }
      }

      @media (max-width: $break-xs-max) {
        width: 60px;
        margin: 0 4px;
      }
    }

    &_box {
      width: 111px;
      height: 111px;
      background-color: #082C61;
      border-radius: 50%;
      border: 3px solid rgba(#548FE7, 0.4);
      margin-bottom: 37px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 3.125rem;
        display: block;
        color: #6E93C9;
      }

      @media (max-width: $break-md-max) {
        width: 100px;
        height: 100px;
        margin-bottom: 25px;

        img {
          max-width: 30px;
        }
        svg {
          font-size: 1.875rem;
        }
      }

      @media (max-width: $break-xs-max) {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;

        img {
          max-width: 20px;
        }
        svg {
          font-size: 1.375rem;
        }
      }
    }
  }
} 