@use '../abstracts/variables' as *;

.word-document {
  background-color: $primary-light-color;
  box-shadow: 0 20px 33px 0 rgba($primary-dark-color, 0.12);
  width: 217px;
  min-width: 217px;
  height: 280px;
  border-radius: 3px;
  padding: 56px 30px 0;
  position: relative;

  &__line {
    background-color: $quinary-light-color;
    height: 4px;
    border-radius: 2px;
    margin-bottom: 10px;

    &--small {
      width: 58px;

      &:nth-child(2) {
        margin-bottom: 17px;
      }
    }

    &--medium {
      width: 105px;
    }

    &--large {
      width: 100%;
    }
  }

  &__img {
    position: absolute;
    left: 0;
    top: 105px;
    transform: translateX(-50%);
  }
}