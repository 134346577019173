@use '../abstracts/variables' as *;

body {
  font-family: $ff-primary;
}

h1 {
  font-size: 2.25rem;
  font-weight: 500;

  &.document-title {
    font-size: 1.0625rem;
    font-weight: 700;
  }
}

h2 {
  font-size: 1.375rem;
  font-weight: 700;

  &.landing-h2 {
    font-family: $ff-tertiary-bold;
    font-size: 2.8125rem;

    @media (max-width: $break-xs-max) {
      font-size: 2.25rem;
    }
  }
}

h3 {
  font-weight: 700;
  font-size: 1.25rem;
  color: $tertiary-text-color;
}

h4 {
  font-size: 1.125rem;
  color: $tertiary-text-color;

  &.with-border {
    font-size: 1.0625rem;
    font-weight: 500;
    color: $primary-dark-color;
    line-height: 1.18;
    padding-bottom: 7px;
    border-bottom: 1px solid $senary-border-color;
  }
}

h5 {
  font-size: 1rem;
  font-weight: 700;
}

h6 {
  font-size: 0.75rem;
  color: $nonary-text-color;
  text-transform: uppercase;
}

a.text-link {
  color: $primary-color;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

p {

  &.landing-p {
    font-family: $ff-tertiary;
    font-size: 1rem;
    line-height: 1.3125;
    color: $quaternary-dark-color;
  }
}

select, button, input, textarea {
  font-family: $ff-primary;
}