@use '../abstracts/variables' as *;

.landing-folder-box {
  width: 160px;
  min-width: 160px;
  height: 160px;
  border-radius: 10px;
  background-color: $primary-light-color;
  padding: 22px 37px;
  position: relative;

  p {
    font-family: $ff-tertiary-bold;
    font-size: 0.875rem;
    text-align: center;
    
    span {
      display: inline-block;
      padding: 4px 10px;
      border-radius: 6px;
    }
  }

  .user {
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
    height: 42px;
    transform: translate(50%, -50%);
    border-radius: 50%;
    
    img {
      display: block;
      border-width: 1px;
      border-style: solid;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 2px 4px 0 rgba(150,150,150,0.5);

      &:nth-child(1) {
        z-index: 1;
      }
      &:nth-child(2) {
        left: -20px;
      }
    }
  }

  &--1 {

    p {
      span {
        color: #9DA2AA;
        background-color: rgba(191, 196, 204, 0.13);
      }
    }

    .user {

      img {
        border-color: #BFC4CC;
      }
    }
  }

  &--2 {

    p {
      span {
        color: #1DAD8A;
        background-color: rgba(106, 236, 204, 0.28);
      }
    }

    .user {

      img {
        border-color: #1DAD8A;
      }
    }
  }

  &--3 {

    p {
      span {
        color: #1D83F2;
        background-color: rgba(38, 135, 243, 0.13);
      }
    }

    .user {

      img {
        border-color: #3080E1;
      }
    }
  }
}