@use '../abstracts/variables' as *;

.landing-icon-box {
  background-color: $primary-light-color;
  box-shadow: 0 20px 33px 0 rgba($shadow-color-10, 0.55);
  border-radius: 8px;
  padding: 24px 26px;
  min-height: 253px;
  width: 225px;
  margin-bottom: 22px;
  // cursor: pointer;

  &__circle {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba($icon-box-circle-bg, 0.44);
    color: $icon-box-circle-text;
    margin-bottom: 23px;
    overflow: hidden;
    
    svg, i, span {
      font-size: 2.75rem;
    }
  }

  p {
    font-family: $ff-tertiary-medium;
    font-weight: 500;
    font-size: 1.1875rem;
    line-height: 1.21;
    color: $text-color-16;
    word-break: break-word;
  }
}