@use '../abstracts/variables' as *;

.landing-use-on-all-devices {
  background-image: url('../../assets/images/colorful_bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 64px 0 98px;
  overflow: hidden;

  h2 {
    text-align: center;
    font-size: 2.5rem;
    color: $primary-light-color;
    text-shadow: 0 2px 4px rgba(105, 7, 70, 0.48);
    max-width: 473px;
    margin: 0 auto 59px;
  }

  &__boxes {
    max-width: 653px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: $break-xs-max) {
      display: block;
    }

    &_box {
      width: 28.63%;
      padding: 22px 20px 17px;
      display: flex;
      flex-direction: column;
      background-color: rgba($primary-light-color, 0.5);
      border-radius: 8px;
      box-shadow: 0 10px 30px 0 rgba($primary-dark-color, 0.15);
      position: relative;
      backdrop-filter: blur(10px);

      @media (max-width: $break-xs-max) {
        width: 187px;
        margin: 0 auto 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // filter: blur(10px);
        background-color: rgba($primary-light-color, 0.5);
        border-radius: 8px;
        border: 1px solid rgba($primary-light-color, 0.3);
        // content: '';
        backdrop-filter: blur(10px);
      }

      .icon-box {
        margin-bottom: 14px;
        position: relative;
        z-index: 1;

        img {
          max-width: 100%;
          display: block;
          margin: 0 auto;
        }
      }

      p {
        margin-top: auto;
        display: inline-flex;
        height: 21px;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        background: rgb(243,243,243);
        background: linear-gradient(180deg, rgba(243,243,243,1) 0%, rgba(227,227,227,1) 100%);
        padding: 0 10px;
        color: #F60F76;
        font-size: 0.8125rem;
        font-family: $ff-quaternary;
        font-weight: 500;
        align-self: center;
        position: relative;
        z-index: 1;
      }
    }
  }
}