@use '../abstracts/variables.scss' as *;

.feature-top-3-items {

  &__inner {
    display: flex;
    padding: 80px 0 81px;
    border-bottom: 1px solid #F3F4F5;

    @media (max-width: $break-sm-max) {
      flex-direction: column;
    }

    .item {
      width: calc(100% / 3);
      min-width: calc(100% / 3);
      padding-right: 29px;

      @media (max-width: $break-sm-max) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        background-color: #19E678;
        border-radius: 50%;
        margin-bottom: 23px;

        svg, 
        span {
          display: block;
          font-size: 1.75rem;
          color: #fff;
        }
      }

      &__title {
        font-size: 1rem;
        line-height: 1.1875;
        color: #0E1E40;
        font-family: $ff-tertiary-bold;
        margin-bottom: 11px;
      }

      &__description {
        font-size: 1rem;
        line-height: 1.3125;
        color: #5D606B;
      }
    }
  }
}