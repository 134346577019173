@use '../abstracts/variables' as *;

.landing-status-and-signature-wrapper {
  padding-top: 144px;
  padding-bottom: 71px;
  background: rgb(247,247,247);
  background: linear-gradient(180deg, rgba(247,247,247,0.37) 0%, rgba(228,222,222,0.69) 100%);
  position: relative;
  overflow: hidden;

  .signature {
    position: absolute;
    bottom: 133px;
    right: 0;
    z-index: -1;

    img {
      display: block;
      max-width: 100%;
    }
  }
}

.landing-status-and-signature {

  .number-of-documents {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;

    p {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      color: #5D606B;
      font-family: $ff-tertiary;
      font-style: italic;
      font-size: 1rem;
      line-height: 1.375;
      border-radius: 20px;
      border: 1px solid #EECFB6;
      padding: 7px 17px;

      span.emojis {
        font-style: normal;
        margin-right: 4px;
      }

      span.number {
        font-size: 1.0625rem;
        font-family: $ff-tertiary-bold;
        color: #F2765C;
        margin-left: 4px;
      }
    }
  }

  &__top {
    text-align: center;
    margin-bottom: 97px;

    .landing-label {
      margin-bottom: 20px;
    }

    h2 {
      font-family: $ff-tertiary-bold;
      font-size: 2.8125rem;
      margin-bottom: 31px;
      color: $text-color-11;
    }

    p {
      font-family: $ff-tertiary;
      line-height: 1.3125;
      color: $quaternary-dark-color;
      max-width: 693px;
      margin: 0 auto;
    }
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .landing-folder-box {
      margin-right: 48px;
      margin-bottom: 48px;
    }
  }
}