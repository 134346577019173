@use '../abstracts/variables' as *;

.landing-security-wrapper {
  background: rgb(41,37,60);
  background: linear-gradient(-5deg, rgba(41,37,60,1) 0%, rgba(46,44,149,1) 100%, rgba(52,51,126,1) 100%);
  padding: 93px 0 118px;
  overflow: hidden;
}

.landing-security {
  display: flex;
  margin-bottom: 51px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $break-md-max) {
    display: block;
  }

  &__left {
    width: 29.7%;
    padding-top: 12px;

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }

    @media (max-width: $break-md-max) {
      width: 100%;
      padding-top: 0;
      margin-bottom: 50px;
    }
  }

  &__right {
    width: 52.88%;
    color: $primary-light-color;

    @media (max-width: $break-md-max) {
      width: 100%;
      padding-right: 0;
    }

    h2 {
      margin-bottom: 8px;
    }

    p { 
      margin-bottom: 0;
      color: $primary-light-color;
    }

    ul {
      margin-top: 21px;

      li {
        font-family: $ff-tertiary;
        font-size: 0.875rem;
        line-height: 1.5;
        color: rgba($primary-light-color, 0.8);
      }
    }
  }

  &__label {
    font-size: 0.6875rem;
    font-family: $ff-tertiary-medium;
    font-weight: 500;
    color: $text-color-17;
    padding: 0 10px;
    text-transform: uppercase;
    background-color: #3F3B82;
    letter-spacing: 0.64px;
    border-radius: 13px;
    margin-bottom: 21px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 25px;
  }

  &__box {
    border: 2px solid $border-color-13;
    border-radius: 13px;
    padding: 8px;
    width: 141px;
    margin: 0 auto;

    &_inner {
      height: 30px;
      border-radius: 6px;
      background-color: $border-color-13;
      display: flex;
      align-items: center;
      padding: 0 9px;
      margin-bottom: 7px;

      &:first-child {
        background-color: #4F49BF;

        .round-rec {
          background: $bg-color-15;
        }

        .circle {
          background-color: $primary-color;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .round-rec {
        background-color: $bg-color-14;
        width: 5px;
        height: 13px;
        border-radius: 5px;
        margin-right: 4px;
      }

      .circle {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: $bg-color-14;
        margin-left: auto;
      }
    }
  }

  &__labels {
    display: flex;
    flex-wrap: wrap;
    margin: 17px 0 8px;

    div {
      padding: 3px 10px 4px;
      border-radius: 4px;
      background-color: $bg-color-13;
      color: $text-color-17;
      font-size: 0.8125rem;
      font-family: $ff-quaternary;
      font-weight: 500;
      line-height: 1.384;
      margin-right: 10px;
      margin-bottom: 12px;
    }
  }
}