@use '../abstracts/variables' as *;

.landing-take-control {
  padding: 66px 0 0;
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;

    &.speedometer {
      margin-bottom: 41px;
    }

    &.heading {
      margin-bottom: 45px;
    }
  }

  &__text-wrap {
    text-align: center;

    p {
      font-family: $ff-tertiary-bold;
      font-size: 0.8125rem;
      letter-spacing: 0.8px;
      line-height: 2;
      color: #655D78;
      padding: 3px 22px;
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 100%);
      display: inline-block;
      border-radius: 3px;
      text-transform: uppercase;
    }
  }

  svg.gradient-heading {
    font-family: $ff-tertiary-bold;
    display: block;
    margin: 0 auto 45px;
    max-width: 100%;
    width: 678px;
    height: 101px;
    text-align: center;

    @media (max-width: $break-md-max) {
      transform: scale(0.8);
    }

    text {
      font-size: 54px;
      position: relative;
      width: 100%;
      display: block;
      text-align: center;

      tspan {
        display: block;
        width: 100%;
        text-align: center;

        &:first-child {
          position: absolute;
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          position: absolute;
          top: 51px;
          left: 50%;
        }
      }
    }
  }
  .take-control-label {
    font-family: $ff-tertiary-bold;
    display: block;
    margin: 0 auto 45px;
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 54px;
    background-color: #f78e64;
    background-image: linear-gradient(175deg, #f78e64, #ed5836);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent
  }
}
