@use '../abstracts/variables' as *;

.landing-document-generation {
  padding: 70px 0 0;
  background: rgb(254,254,254);
  background: linear-gradient(180deg, rgba(254,254,254,1) 0%, rgba(255,247,238,1) 100%);

  .icon-top {
    display: block;
    margin: 0 auto 21px;
  }

  h2 {
    color: #0E1E40;
    text-align: center;
    margin-bottom: 22px;
  }

  p {
    max-width: 589px;
    margin: 0 auto -30px;
    text-align: center;
  }

  &__papers {
    width: 716px;
    margin: 0 auto;
    display: flex;
    position: relative;
    z-index: 1;
    top: 64px;

    > div {

      &:nth-child(2),
      &:nth-child(3) {
        margin-left: -33.2%;
      }
      &:nth-child(4) {
        margin-left: -34.3%;
      }
      &:nth-child(5) {
        margin-left: -35%;
      }
    }

    .paper {
      padding: 88px 6.2%;

      // &:nth-child(2),
      // &:nth-child(3) {
      //   margin-left: -33.2%;
      // }
      // &:nth-child(4) {
      //   margin-left: -34.3%;
      // }
      // &:nth-child(5) {
      //   margin-left: -35%;
      // }
    }

    @media (max-width: $break-md-max) {
      width: 440px;

      > div {
        height: 285px;
        margin: 0 auto;
        max-width: 338px;
        width: 90%;
      }

      .paper {
        height: 285px;
        padding: 44px 6.2%;
      }
    }

    @media (max-width: $break-xs-max) {
      width: 280px;

      > div {
        height: 200px;
      }

      .paper {
        height: 200px;
        padding: 40px 6.2%;

        &__line {
          margin-bottom: 10px;
          height: 3px;
        }
      }
    }
  }
}