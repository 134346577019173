@use '../abstracts/variables' as *;

.variables {
  font-size: 0.875rem;
  color: $primary-color;
  margin-top: 5px;
  display: block;
  width: 100%;

  h1 {
    font-size: 1.625rem;
    font-weight: 500;
    color: $secondary-bg-color;
    line-height: 1.16;
  }
  
  h2 {
    font-size: 1.0625rem;
    line-height: 1.3;
    font-weight: 400;
    color: $text-color-28;
    padding: 10px 16px;
    background-color: rgba($secondary-bg-color, 0.07);
    width: 100%;
    border-radius: 3px;
  }

  .form-input {

    &__field {
      background-color: $bg-color-24 !important;
      padding: 3px 6px !important;
  
      &:focus {
        border-color: $bg-color-25 !important;
      }
    }

    &.not-empty {

      .form-input__field {
        background-color: rgba($bg-color-27, 0.08) !important;
        color: $bg-color-26 !important;
        
        &:focus {
          border-color: $bg-color-26 !important;
        }
      }
    }
  }

  .textarea {

    textarea {
      background-color: $bg-color-24 !important;
      padding: 3px 6px !important;
  
      &:focus {
        border-color: $bg-color-25 !important;
      }
    }

    &.not-empty {

      textarea {
        background-color: rgba($bg-color-27, 0.08) !important;
        color: $bg-color-26 !important;
        
        &:focus {
          border-color: $bg-color-26 !important;
        }
      }
    }
  }

  .date_picker .react-datepicker-wrapper .react-datepicker__input-container input {
    background-color: $bg-color-24 !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;

    &:focus {
      border-color: $bg-color-25 !important;
    }
  }

  .date_picker.not-empty .react-datepicker-wrapper .react-datepicker__input-container input {
    background-color: rgba($bg-color-27, 0.08) !important;
    color: $bg-color-26 !important;
    
    &:focus {
      border-color: $bg-color-26 !important;
    }
  }

  .question-el {

    p {
      color: $bg-color-28;
    }

    .variables__head:hover {
      background-color: rgba($bg-color-28, 0.07);
    }

    .form-radio__choices {

      &_label {

        &:hover {
          background-color: rgba($bg-color-28, 0.07);
        }
      }

      &_btn {
        border-color: $bg-color-28;

        &::after {
          background-color: $bg-color-28;
        }
      }
    }

    &.selected {

      p {
        color: $senary-color;

        &.selected {
          color: $text-color-29;
        }
      }

      .variables__head {

        &:hover {
          background-color: rgba($bg-color-29, 0.07);

          p {
            color: $text-color-29;
          }

          .variables__head_text {

            .radio {
              border-color: $bg-color-30;
  
              &::after {
                background-color: $bg-color-30;
              }
            }
          }

          .variables__head_selected {

            .button {
              background-color: rgba($bg-color-28, 0.07);
              border-color: transparent;
              padding: 0 5px;

              .icon {
                margin-right: 0;
              }

              &:hover {
                background-color: $bg-color-28;
              }
            }
          }
        }
      }

      .form-radio__choices {

        &_label {
  
          &:hover {
            background-color: rgba($bg-color-29, 0.07);

            p {
              color: $text-color-29;
            }
            .form-radio__choices_btn {
              border-color: $bg-color-30;
              
              &::after {
                background-color: $bg-color-30;
              }
            }
          }
        }
  
        &_btn {
          border-color: $senary-color;
  
          &::after {
            background-color: $senary-color;
          }
        }
      }
    }
  }

  .doc-variable-el {

    &__span {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      z-index: -1;
      font-size: 0.875rem;
      font-weight: 700;
      padding: 0 12px;
      min-width: 50px;
      max-width: 100% !important;
      // white-space: nowrap;

      &--number {
        padding: 0 20px;
      }
    }

    &--with-icon {
      position: relative;
      padding-right: 30px;

      .icon {
        position: absolute;
        top: 50%;
        right: 8px;
        width: 24px;
        height: 24px;
        margin-top: -12px;
        border-radius: 4px;
        background-color: rgba($primary-color, 0.2);
        color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          font-size: 1rem;
        }

        &:hover {
          background-color: $primary-color;
          color: #fff;
        }
      }
    }
  }

  .radio {
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 50%;
    display: block;
    border: 1px solid $bg-color-28;
    position: relative;
    margin-right: 10px;

    &::after {
      width: 8px;
      height: 8px;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $bg-color-28;
      border-radius: 50%;
    }
  }

  &__flex {
    display: flex;
  }

  &.opened {

    > .variables__head {
      color: $quinary-text-color;

      .radio {
        border-color: $quinary-text-color;

        &::after {
          background-color: $quinary-text-color;
        }
      }
    }

    > .variables__body {
      display: block;
    }
  }

  &.opened-2 {

    > .variables__body {
      display: block;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 3px 0;
    flex-wrap: wrap;

    @media (max-width: $break-md-max) {
      display: block;

      .variables__head_buttons {
        margin-top: 15px;
      }
    }

    &.select {

      .variables__head_text {

        p {
          padding: 3px 0 2px;
        }
      }
    }

    &_text {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.cursor-default {
        cursor: default;
      }

      p {
        padding: 8px 0;
      }

      p.selected {
        margin-right: 10px;
        font-weight: 700;
        text-transform: uppercase;
        margin-left: 10px;
      }
    }

    p {
      font-weight: 500;
    }

    &_buttons {
      display: none;
      align-items: center;
      flex-wrap: wrap;

      .button {
        margin-left: 10px;
        min-height: 25px;
        height: auto;
        margin-top: 2px;
        margin-bottom: 2px;
      }
    }

    &_selected {
      display: none;
      align-items: center;
      margin-left: 10px;

      p {
        margin-right: 10px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .button {
        height: 25px;
      }
    }

    &:hover {

      .variables__head_buttons {
        display: flex;
      }
    }
  }

  &__body {
    // border-left: 1px solid $primary-border-color;
    // padding-left: 18px;
    margin-left: 8px;
    margin-top: 5px;
    display: none;

    .variables__head {
      padding: 10px 5px;

      &:hover {
        background-color: rgba($primary-color, 0.12);
      }
    }

    .question-el {

      .variables__head {
        padding: 5px;
      }
    }

    .variables__body {
      margin-left: 13px;
    }

    .form-radio {
      
      &__choices {
        
        &_label {
          padding: 10px 5px;

          &:hover {
            background-color: rgba($primary-color, 0.12);
          }

          p {
            line-height: 32px;
          }
        }
      }
    }

    .choices {
      padding-bottom: 20px;

      &__inner {
        border-bottom: 1px solid $primary-border-color;
      }

      &.hide {
        display: none;
      }
    }

    .fields {
      display: none;

      &__box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
          font-size: 1rem;
          color: $primary-text-color;
          line-height: 32px;
        }

        .form-input {
          // max-width: 140px;
          margin-bottom: 0;
          margin-left: 5px;
        }
      }
    }

    &.fields-opened {

      .fields {
        display: block;
      }
    }

    .multiselect {
      color: $primary-dark-color;
    }
  }

  &__text {
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;
    line-height: 2;
    color: $primary-text-color;
    font-size: 1rem;
    margin-bottom: 10px;
    word-break: break-word;
    white-space: pre-wrap;

    .form-input {
      // max-width: 140px;
      margin: 0 10px;
    }

    .tooltip-wrapper {
      display: inline-block;
      max-width: 100%;
      /* SCROLLING TASK */
      // display: block; 
      /* SCROLLING TASK */
    }

    .textarea-el {
      display: block;
    }

    .variables__head_text.tooltip-wrapper {
      display: flex;
    }

    span.content-to-edit {
      outline: 0;
    }
  }

  &.selected {

    .variables__head {
      
      &_text {
        color: $quinary-text-color;

        .radio {
          border-color: $quinary-text-color;

          &::after {
            background-color: $quinary-text-color;
          }
        }
      }

      &_selected {
        color: $quinary-text-color;
        display: flex;

        .button {
          display: none;
        }
      }

      &_buttons {
        display: none;
      }

      &:hover {

        .variables__head {

          &_text {
            color: $primary-color;
    
            .radio {
              border-color: $primary-color;
    
              &::after {
                background-color: $primary-color;
              }
            }
          }

          &_selected {
            color: $primary-color;

            button {
            display: inline-block;
          }
          }
        }
      }
    }

    .variables__body {

      .choices {
        display: none;
      }
    }
  }

  &.selected-2 {

    .variables__body {

      .choices {
        display: block;
      }
    }
  }
}