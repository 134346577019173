.landing {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 94px; // fixed header height

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .landing-footer-wrapper {
    margin-top: auto;
  }
}