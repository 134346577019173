@use '../abstracts/variables' as *;

.teams {

  h4 {
    font-size: 0.875rem;
    color: $secondary-text-color;
    margin-bottom: 41px;
  }

  &__list {

    &_item {
      display: flex;
      border-bottom: 1px solid $tertiary-bg-color;
      padding-bottom: 26px;
      margin-bottom: 23px;

      @media (max-width: $break-sm-max) {
        display: block;
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }

      .item-left {
        display: flex;
        align-items: center;

        @media (max-width: $break-sm-max) {
          margin-bottom: 20px;
        }

        &__logo-box {
          width: 40px;
          height: 40px;
          background-color: $form-el-bg-color-1;
          border: 1px solid $form-el-border-color-1;
          border-radius: 2px;
          margin-right: 18px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }

        .letter-circle {
          width: 40px;
          height: 40px;
          border-radius: 0;
          margin-right: 18px;
        } 

        &__title {
          font-size: 1.1875rem;
          font-weight: 500;
          color: $secondary-text-color;
        }
      }

      .item-right {
        margin-left: auto;
        display: flex;
        align-items: center;

        @media (max-width: $break-sm-max) {
          padding-left: 58px;
          display: block;
        }

        &__users {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-right: 17px;

          &_img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            margin-left: 8px;
            background-color: $arrow-color;

            @media (max-width: $break-sm-max) {
              margin-bottom: 8px;

              &:first-child {
                margin-left: 0;
              }
            }
          }

          .letter-circle {
            margin-left: 8px;
            width: 28px;
            height: 28px;

            @media (max-width: $break-sm-max) {
              margin-bottom: 8px;

              &:first-child {
                margin-left: 0;
              }
            }
          }
        }

        .button {
          font-size: 0.6875rem;
          font-weight: 700;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          height: 28px !important;

          @media (max-width: $break-sm-max) {
            margin-top: 8px;
          }
        }
      }
    }
  }
}