@use '../abstracts/variables' as *;

.landing-try {
  padding: 61px 0 71px;

  h2 {
    margin-bottom: 21px;
    color: $septenary-dark-color;
    text-align: center;
  }

  p {
    font-family: $ff-tertiary;
    color: $tertiary-dark-color;
    max-width: 546px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.3125;
  }

  &__actions {
    max-width: 641px;
    margin: 53px auto 16px;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    margin-bottom: 19px;

    .button {

      &.landing--white {
        margin-right: 20px;
      }
    }

    @media (max-width: $break-sm-max) {
      flex-direction: column;
    }

    .button--outline-primary {
      height: 56px;
      color: #16B5D2;
      border-color: #19C6E6;
      margin-right: 20px;
      border-radius: 6px;
      padding: 0 16px;
      font-size: 1rem;
      line-height: 1.1875;
      font-family: $ff-tertiary-bold;
      border-width: 2px;
      margin-bottom: 0;

      &:hover {
        background-color: #19C6E6;
        color: #fff;
      }
    }

    @media (max-width: $break-md-max) {
      // display: block;

      .button {
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }

    @media (max-width: $break-xs-max) {
      .button {
        min-width: auto;
      }
    }
  }

  ul {
    max-width: 298px;
    margin: 0 auto;
    padding-left: 20px;

    li {
      font-family: $ff-tertiary;
      display: flex;
      align-items: center;
      color: $octonary-dark-color;
      font-size: 0.75rem;
      letter-spacing: 0.6px;
      margin-bottom: 5px;

      svg {
        display: block;
        color: $check-color;
        font-size: 1.125rem;
        margin-right: 7px;
      }
    }
  }

  &--light {
    background-color: #fff;
    padding: 80px 0 110px;

    @media (max-width: $break-md-max) {
      padding: 40px 0;
    }

    h2 {
      color: #0F1F3F;
      margin-bottom: 12px;
    }

    p {
      font-family: $ff-tertiary;
      font-size: 1rem;
      line-height: 1.4375;
      color: #10203E;
      max-width: 546px;
    }

    .landing-try__actions {
      margin-top: 32px;
    }
  }

  &--dark {
    background-color: #091937;
    padding: 79px 0 92px;

    @media (max-width: $break-md-max) {
      padding: 40px 0;
    }

    h2 {
      color: #fff;
    }

    p {
      font-family: $ff-tertiary-medium;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.27;
      max-width: 706px;
      color: #fff;
    }

    .landing-try__actions {
      margin-top: 39px;
    }
  }

  &--spacing-top {
    margin-top: 100px;
  }
}