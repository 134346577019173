@use '../abstracts/variables' as *;

.landing-partners {
  padding: 0 0 148px;
  overflow: hidden;

  h5 {
    font-family: $ff-tertiary;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.6px;
    color: $text-color-24;
    margin-bottom: 63px;
  }
  
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  img {
    display: block;
    margin: 0 45px 50px;
  }

  @media (max-width: $break-lg-max) {
    &__inner {
      justify-content: center;
    }
  }
  
  @media (max-width: $break-md-max) {
    img {
      margin: 0 30px 30px;
    }
  }
}