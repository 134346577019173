@use '../abstracts/variables' as *;

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-light-color;
  z-index: 99999;
  transition: opacity .3s ease;

  &.hidden {
    opacity: 0;
    z-index: -99;
  }
}