@use '../abstracts/variables.scss' as *;

.page {
  border-top: 1px solid $primary-border-color;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    padding: 80px 0;

    p {
      text-align: center;
      margin-bottom: 15px;

      span {
        background-color: #19C6E6;
        color: #fff;
        border-radius: 4px;
        display: inline-block;
        padding: 5px 10px;
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 1px;
      }
    }

    h1 {
      text-align: center;
      color: #0E1E40;
    }

    h2 {
      color: #0E1E40;
      font-weight: 500;
      text-align: center;
      margin-top: 20px;
    }

    .breadcrumbs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      flex-wrap: wrap;

      li {
        font-size: 0.875rem;

        a {
          color: #19C6E6;
        }

        &.icon {

          svg {
            display: block;
            color: #0E1E40;
          }
        }
      }
    }
  }

  &__main {
    flex: 1;
    background-color: $primary-bg-color;
    padding: 60px 0;
    
    &_articles {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}