@use '../abstracts/variables' as *;

.dashboard-box-container {
  background-color: $primary-light-color;
  box-shadow: 0 2px 4px 0 rgba($shadow-color-7, 0.5);
  border-radius: 3px;
  padding: 16px 23px 35px;
  margin-bottom: 32px;
  position: relative;

  &::before {
    content: '';
    width: 70px;
    height: 4px;
    background-color: $primary-color;
    border-radius: 0 0 2px 2px;
    position: absolute;
    left: 22px;
    top: 0;
  }

  &--half-width {
    max-width: 50%;

    @media (max-width: $break-lg-max) {
      max-width: 100%;
    }
  }

  &--flex {
    display: flex;

    @media (max-width: $break-md-max) {
      display: block;
    }

    .dashboard-box-container__left {
      flex: 1;
      padding-top: 15px;
    }

    .dashboard-box-container__right {
      width: 228px;
      margin-left: 64px;
      padding-top: 21px;

      @media (max-width: $break-md-max) {
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .dashboard-small-box:first-child {
          margin-right: 15px;
        }
      }
    }
  }

  &__head {
    margin-bottom: 20px;
    display: flex;

    @media (max-width: $break-sm-max) {
      flex-wrap: wrap;
    }

    &_box {
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }

      h6 {
        font-size: 0.9375rem;
        font-weight: 500;
        line-height: 1.2;
        color: $quinary-text-color;
        margin-bottom: 10px;
        text-transform: none;
        transition: color .3s ease;

        .custom-select {
          
          .select-dropdown {
            top: calc(100% + 5px);
          }

          &__selected {
            color: #34353B;
            font-size: 0.9375rem;
            font-weight: 500;
            line-height: 1.2;
    
            svg {
              display: block;
            }
          }
        }
      }
      
      p {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1;
        color: $quinary-text-color;
        transition: color .3s ease;
      }
      
      &.active {
        
        h6,
        p {
          color: $secondary-text-color;
        }
      }

      &.clickable {
        cursor: pointer;
      }

      &.meta {
        margin-left: auto;
        display: flex;
        align-items: center;
        align-self: flex-start;
        padding-top: 4px;

        .custom-datepicker {
          margin-left: 29px;
        }

        .show-all {
          color: $secondary-text-color;
          padding: 0 14px;
          font-weight: 400;
        }

        .button {
          padding: 0 8px 0 6px;

          .icon {
            margin-right: 3px;
            
            svg {
              font-size: 1.125rem;
            }
          }
        }

        @media (max-width: $break-sm-max) {
          width: 100%;
          margin-top: 20px;
          flex-wrap: wrap;

          .custom-select {
            margin-right: 15px;
          }

          .custom-datepicker {
            margin-left: 0;

            &__dropdown {
              right: auto;
              left: 0;
            }
          }
        }
      }
    }
  }

  &__body {

    .deadlines {
      margin-bottom: 25px;

      &__item {
        display: flex;
        align-items: center;
        padding: 6px 14px 6px 7px;
        margin-bottom: 10px;
        background-color: $dashboard-bg;
        border-radius: 3px;
        transition: background-color .3s ease;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        &_name {
          font-size: 0.875rem;
          line-height: 1.286;
          color: $secondary-bg-color;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          min-width: 0;
          
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
          }

          svg {
            font-size: 1.25rem;
            display: block;
            margin-right: 7px;
            color: $text-color-26;
          }
        }

        &_owner {
          margin-left: 10px;
          font-size: 0.6875rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 1px 5px;
          border: 1px solid rgba(#07A97A, 0.3);
          border-radius: 2px;
        }

        &_right {
          margin-left: auto;
        }

        &_date {
          color: $senary-color;
          font-size: 0.75rem;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 5px;

          span {
            font-weight: 500;
            color: $secondary-text-color;
          }
        }

        &_hover {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          padding: 6px 8px 6px 7px;
          display: flex;
          align-items: center;
          display: none;
          background-color: #E7E9EE;
          transition: background-color .3s ease;
          border-left: 1px solid rgba(#07A97A, 0.1);

          .doc {
            background-color: $primary-light-color;
            color: $primary-color;
            box-shadow: 0 2px 4px 0 rgba($shadow-color-12, 0.5);
            font-size: 0.75rem;
            border-radius: 3px;
            display: flex;
            align-items: center;
            height: 22px;
            padding: 0 7px 0 4px;
            transition: background-color .3s ease;
            min-width: 0;
            max-width: 150px;

            span {
              display: block;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              max-width: 200px;
            }

            svg {
              font-size: 1.0625rem;
              margin-right: 4px;
            }

            &:hover {
              background-color: darken($primary-light-color, 5);
            }
          }

          .delete {
            color: $nonary-text-color;
            margin-left: 7px;
            transition: color .3s ease;
            
            svg {
              font-size: 1.25rem;
              display: block;
            }

            &:hover {
              color: $text-color-26;
            }
          }
        }

        &:hover {
          background-color: $bg-color-23;

          .deadlines__item_date {
            display: none;
          }

          .deadlines__item_hover {
            display: flex;
          }
        }

        &--past {
          border: 1px solid $quinary-color-light;
          background-color: transparent;

          .deadlines__item {

            &_name {
              color: $senary-color;

              svg {
                color: $senary-color;
              }
            }

            &_date {

              span {
                color: $senary-color;
              }
            }
          }
        }
      }
    }

    .all-items-btn-wrapper {

      a {
        height: 24px;
        border: 1px solid #BBBBBB;
        border-radius: 3px;
        padding: 5px 7px;
        background-color: transparent;
        outline: 0;
        font-size: 0.75rem;
        line-height: 1.166666667;
        color: #767676;
        cursor: pointer;

        &:hover {
          color: #34353B;
        }

        &.disabled {
          opacity: 0.8;
          cursor: default;
        }
      }
    }

    .tags-categories-bar {
      height: 10px;
      background-color: $primary-bg-color;
      border-radius: 5px;
      display: flex;
      margin-bottom: 31px;

      &__item {

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }

        &--single {
          border-radius: 5px !important;
        }
      }
    }

    .tags-categories-list {
      columns: 2;

      @media (max-width: $break-xs-max) {
        columns: 1;
      }

      li {
        margin-bottom: 13px;
        padding-left: 18px;
        position: relative;

        span.dot {
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 8px;
          position: absolute;
          top: 5px;
          left: 0;
        }

        p {
          font-size: 0.9375rem;
          color: $secondary-text-color;
          font-weight: 500;
          margin-right: 6px;
          line-height: 18px;
          display: inline-block;
          position: relative;
          padding-right: 35px;

          span {
            width: 29px;
            height: 15px;
            line-height: 15px;
            background-color: $nonary-text-color;
            color: $primary-light-color;
            border-radius: 7px;
            font-size: 0.625rem;
            font-weight: 700;
            text-align: center;
            display: block;
            position: absolute;
            top: 2px;
            right: 0;
          }
        }

      }
    }
  }

  &.tasks-page {
    padding: 44px 59px;

    @media (max-width: $break-md-max) {
      padding: 44px 20px;
    }

    &::before {
      display: none;
    }

    .dashboard-box-container__head {

      h6 {
        font-size: 1.125rem;
        font-weight: 400;
        color: $color-12;
      }
    } 
  }
}