@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.dashboard-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 34px 14px 34px;
    // box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
    border-bottom: 1px solid $nonary-border-color;
    position: relative;
    
    h1 {
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.2;

      @media (max-width: $break-xs-max) {
        font-size: 1.125rem;
      }
    }

    .line-loader {
      top: 100%;
    }

    .search-wrap {
      width: calc(100% - 24px);
      left: 12px;
    }

    .search-trigger-wrapper {
      position: relative;

      .close {
        position: absolute;
        left: 100%;
        top: 0;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
      }
    }

    .search-trigger {
      width: 244px;
      height: 32px;
      background-color: rgba(#EBECF0, 0.35);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        font-size: 1.125rem;
        color: #767676;
      }

      p {
        font-size: 0.875rem;
        margin-left: 2px;
        color: #767676;
      }

      @media (max-width: $break-md-max) {
        width: 36px;

        p {
          display: none;
        }
      }
    }

    &_right {
      display: flex;
      align-items: center;

      .dashboard-btn-wrapper {
        margin-left: 8px;

        .icon-button,
        .username {
          margin-left: 0;
        }
      }

      .icon-button {
        margin-left: 8px;
        width: 36px;
        color: $nonary-text-color;
        background-color: rgba(#EBECF0, 0.35);

        &:hover {
          background-color: darken(rgba(#EBECF0, 0.35), 5);
        }
      }

      @include documentsDropdownWrapper;
      @include username {
        margin-left: 8px;
      }

      @media (max-width: $break-xs-max) {
        .add-documents-dropdown {
          right: 0;
          left: auto;
        }

        .button {
          padding: 0 7px; 

          .text {
            display: none;
          }
        }
      }
    }

    @media (max-width: $break-sm-max) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  
  &__body {
    background-color: $dashboard-bg;
    display: flex;
    flex: 1;
    flex-direction: column; // v2
    padding: 30px 34px; // v2

    &--white-bg {
      background-color: #fff;
    }

    @media (max-width: $break-sm-max) {
      flex-direction: column;
      padding-left: 12px;
      padding-right: 12px;
    }

    &.filter {
      flex-direction: column;
      padding: 0;

      .dashboard-view {
        flex: 1;
      }
    }
    
    h5 {
      font-size: 0.875rem;
      font-weight: 400;
      color: $nonary-text-color;
      margin-bottom: 20px;
    }

    &_left {
      flex: 1;
      padding: 30px 19px 30px 0;
    }

    &_right {
      width: 298px;
      padding: 30px 0 30px 19px;
      border-left: 1px solid rgba($quaternary-border-color, 0.42);

      @media (max-width: $break-sm-max) {
        border-left: 0;
        border-top: 1px solid rgba($quaternary-border-color, 0.42);
        width: 100%;
        padding-left: 0;
      }
    }

    &_row {
      display: flex;

      @media (max-width: $break-lg-max) {
        display: block;
      }

      .dashboard-box-container {
        flex: 1;
        height: 390px;

        @media (max-width: $break-md-max) {
          height: auto;
        }

        &.tasks-container {
          // min-width: 330px;
          // max-width: 38.82%;
          margin-left: 17px;

          @media (max-width: $break-lg-max) {
            min-width: 1px;
            max-width: 100%;
            margin-left: 0;
          }
        }
      }

      &.two-cols {

        .dashboard-box-container {

          &:first-child {
            margin-right: 8px;
          }

          &:last-child {
            margin-left: 8px;
          }

          @media (max-width: $break-lg-max) {
            &:first-child {
              margin-right: 0;
            }
            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    .dashboard-view {

      &__head {

        &_top,
        &_bottom {
          padding-left: 34px;
          padding-right: 34px;
        }

        &_top {

          h4 span {
            display: none;
          }

          @media (max-width: $break-sm-max) {
            padding-left: 12px;
            padding-right: 12px;
            
            .search-wrap {
              width: calc(100% - 24px);
              left: 12px;
            }
          }
        }
      }

      &__body {

        .dashboard-row {
          padding-left: 34px;
          padding-right: 34px;

          @media (max-width: $break-md-max) {
            .more {
              right: 30px;
            }
          }

          @media (max-width: $break-sm-max) {
            padding-left: 12px;
            padding-right: 12px;

            .more {
              right: 15px;
            }
          }
        }
      }
    }
  }
}