@use '../abstracts/variables.scss' as *;

.documents-preview-overlay { 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background-color: rgba(#000, 0.33);
  z-index: 101;

  &--2 {
    z-index: 103;
  }
}

.documents-preview-panel {
  position: fixed;
  right: -978px;
  top: 0;
  max-width: 978px;
  width: 90%;
  height: 100%;
  background-color: #F1F7FA;
  border-radius: 2px 0 0 2px;
  z-index: 102;
  transition: right .3s ease, width .3s ease;
  display: flex;
  flex-direction: column;

  &.opened {
    right: 0;
  }

  &.extend {
    right: 100px;
  }

  &.single {
    right: -978px;
    max-width: 978px;
    z-index: 104;

    &.single-opened {
      right: 0;
    }
  }

  &__head {
    background-color: #fff;
    padding: 23px 75px 20px;
    position: relative;
    border-radius: 2px 0 0 0;

    @media (max-width: $break-md-max) {
      padding: 23px 40px 20px;
    }

    h2 {
      font-size: 1.25rem;
      font-family: $ff-tertiary;
      font-weight: 400;
      color: #0E1E40;
      line-height: 1.2;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    left: 22px;
    color: #B6BBC5;
    cursor: pointer;

    @media (max-width: $break-md-max) {
      left: 5px;
    }
    
    svg {
      font-size: 1.875rem;
    }
  }

  &__body {
    padding: 40px 75px;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;

    @media (max-width: $break-md-max) {
      padding: 40px;
    }
    
    h2 {
      font-size: 1.5rem;
      font-family: $ff-tertiary-medium;
      font-weight: 500;
      color: #0E1E40;
      line-height: 1.2;
      margin-bottom: 38px;
    }
    
    .documents-wrapper {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      width: calc(100% + 20px);
      
      &--loading {
        height: 100%;
      }

      .loading-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        flex: 1;

        p {
          font-size: 1rem;
          line-height: 1.375;
          color: #497084;
          font-style: italic;
          max-width: 290px;
          text-align: center;
        }
      }

      .document-box {
        width: 189px;
        height: 207px;
        border: 1px solid #D9E1E4;
        border-radius: 8px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: pointer;

        &__main {
          padding: 26px 28px 15px;
          flex: 1;
          height: 172px;
          overflow: hidden;

          h3 {
            font-size: 1rem;
            line-height: 1.1875;
            color: #030A4E;
            margin-bottom: 20px;
            word-wrap: break-word;
          }

          p {
            font-size: 0.9375rem;
            line-height: 1.46;
            color: #97989A;
          }
        }

        &__foot {
          border-top: 1px solid #EFF0F1;
          display: flex;
          align-items: center;
          opacity: 0;
          transition: opacity .3s ease;
          height: 35px;

          p {
            min-width: 50%;
            max-width: 50%;
            display: flex;
            align-items: center;
            height: 34px;
            font-size: 0.6875rem;
            padding-left: 13px;
            color: #97989A;
            text-transform: uppercase;

            svg {
              margin-right: 8px;
              display: block;
              color: inherit;
              font-size: 1.125rem;
            }

            &:first-child {
              border-right: 1px solid #EFF0F1;
            }
          }
        }

        &:hover {

          .document-box__foot {
            opacity: 1;
          }
        }
      }
    }
  }

  &__body-single {
    display: flex;
    overflow-y: auto;
    padding: 42px 0 42px 66px;

    @media (max-width: $break-md-max) {
      padding: 40px;
      flex-direction: column;
    }

    .single-doc-preview {
      flex: 1;
      margin-right: 20px;
      align-self: flex-start;

      @media (max-width: $break-md-max) {
        width: 100%;
        order: 2;
        margin-right: 0;
      }

      .preview-page {
        background-color: #fff;
        border: 1px solid #D9E1E4;
        border-radius: 8px;
        height: 740px;
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .single-doc-info {
      background-color: #fff;
      border: 1px solid #D9E1E4;
      border-right: 0;
      border-radius: 8px 0 0 8px;
      position: sticky;
      top: 0;
      padding: 24px 23px 28px 29px;
      max-width: 276px;
      height: auto;
      align-self: flex-start;

      @media (max-width: $break-md-max) {
        order: 1;
        margin-bottom: 20px;
        max-width: 100%;
        border-radius: 8px;
        border-right: 1px solid #d9e1e4;
        position: static;
      }

      .description {
        font-size: 0.875rem;
        color: #476AB1;
        line-height: 1.43;
        margin-bottom: 23px;
      }

      &__foot {
        display: inline-flex;
        align-items: center;
        border: 1px solid #476AB1;
        border-radius: 3px;
        background-color: #F9FAFD;

        p {
          display: flex;
          align-items: center;
          height: 30px;
          font-size: 0.625rem;
          padding-left: 15px;
          padding-right: 14px;
          color: #6980B0;

          @media (max-width: $break-sm-max) {
            padding-left: 5px;
            padding-right: 5px;
          }

          svg {
            margin-right: 7px;
            display: block;
            color: #486EAF;
            font-size: 1.125rem;
            position: relative;
            top: -1px;
          }

          &:first-child {
            border-right: 1px solid #C0CCE5;
          }
        }
      }
    }
  }
}