@use '../abstracts/variables' as *;

.notification-button-wrapper {
  position: relative;
  margin-left: auto;
}

.notification-button-badge {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: $notification-danger-icon;
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.notifications {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  &.active {

    .notifications__overlay {
      opacity: 1;
    }

    .notifications__inner {
      right: 0;
    }

    &.mobile-menu {

      .notifications__inner {
        right: 0;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary-dark-color, 0.6);
    opacity: 0;
    transition: opacity .3s ease;
  }

  &.mobile-menu {

    .notifications__inner {
      right: -250px;
      width: 250px;

      @media (max-width: $break-xs-max) {
        right: -250px;
        width: 250px;
      }
    }
  }

  &__inner {
    position: fixed;
    top: 0;
    right: -346px;
    width: 346px;
    height: 100%;
    background-color: $primary-light-color;
    padding: 17px 13px;
    overflow-y: auto;
    transition: right .3s ease;

    @media (max-width: $break-xs-max) {
      right: -306px;
      width: 306px;
    }

    &_head {
      background-color: $septenary-border-color;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      margin-bottom: 24px;

      h5 {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: 500;
        color: $tertiary-dark-color;
        margin-left: 10px;
        letter-spacing: 1px;
      }

      svg {
        font-size: 1rem;
        display: block;
      }
    }
  }

  &__list {

    li {
      margin-bottom: 22px;
      padding-bottom: 22px;
      border-bottom: 1px solid $secondary-border-color;
      display: flex;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      .badge {
        position: absolute;
        top: 5px;
        left: 35px;
        background-color: $notification-danger-icon;
        width: 12px;
        height: 12px;
        border-radius: 6px;
      }

      .icon {
        width: 50px;
        min-width: 50px;
        height: 50px;
        margin-right: 16px;
        background-color: $senary-bg-color;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg, i {
          font-size: 1.25rem;
        }
      }

      .text {
        flex: 1;

        p {
          
          &.info {
            color: $secondary-bg-color;
            font-size: 0.875rem;
            line-height: 18px;
            font-weight: 400;
            margin-bottom: 8px;
  
            strong {
              font-weight: 500;
            }
  
            a {
              text-decoration: underline;
              color: inherit;
            }
          }
  
          &.date {
            color: $nonary-text-color;
            font-size: 0.8125rem;
          }
        }
      }

      &.document-action {

        .icon {
          background-color: rgba($primary-color, 0.1);
          color: $primary-color;
          
          svg {
            font-size: 1.5rem;
          }
        }
      }
      &.alert-action {

        .icon {
          background-color: rgba($action-alert-bg, 0.1);
          color: $action-alert-text;
        }
      }
      &.task-action {

        .icon {
          background-color: rgba($action-task-bg, 0.1);
          color: $action-task-bg;
        }
      }
      &.contact-action {

        .icon {
          background-color: rgba($action-contact-bg, 0.1);
          font-size: 1.375rem;
          color: $action-contact-text;
        }
      }
      &.template-action {

        .icon {
          background-color: rgba($action-template-bg, 0.1);
          color: $action-template-bg;
        }
      }
      &.signature-action {

        .icon {
          background-color: rgba($quinary-color, 0.1);
          color: $quinary-color;
        }
      }
      &.comment-action {
        .icon {
          background-color: rgba($primary-color, 0.1);
          color: $primary-color;
        }
      }
    }
  }
}