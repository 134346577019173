@use '../abstracts/variables.scss' as *;

.feature-intro {
  padding: 119px 0 152px;
  background: rgb(234,244,249);
  background: radial-gradient(circle, rgba(234,244,249,1) 0%, rgba(249,252,253,1) 100%);
  overflow: hidden;

  @media (max-width: $break-sm-max) {
    padding: 80px 0 120px;
  }

  &__inner {
    display: flex;
  }

  &__left {
    max-width: 473px;
    width: 100%;
    color: #0E1E40;
    margin-right: 41px;
    min-height: 376px;

    @media (min-width: $break-lg-min) {
      max-width: 665px;
    }

    h1 {
      font-family: $ff-tertiary-bold;
      font-size: 2.375rem;
      line-height: 1.21;
      margin-bottom: 30px;
    }

    p {
      font-size: 1.0625rem;
      line-height: 1.52;
      letter-spacing: 0.2px;
      margin-bottom: 26px;
    }

    .button {
      height: 59px;
      padding: 0 18px 0 21px;
      font-size: 1.125rem;
      letter-spacing: 0.39px;
    }
  }

  &__right {
    position: relative;

    .image-box {
      position: absolute;
      top: -11px;
      left: 0;
      width: 580px;
      height: 412px;
      background-color: #C3C2C2;
      border-radius: 6px;
    }

    img {
      position: absolute;
      top: -11px;
      left: 0;
      max-width: none;
      max-height: 412px;
    }
  }
}