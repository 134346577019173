@use '../abstracts/variables' as *;

.document-detail-sp-section {
  padding: 20px 35px 50px 23px;

  h4 {
    margin-bottom: 29px;
    display: flex;
    align-items: center;

    .icon {
      color: $primary-color;
      display: block;
      border-radius: 3px;
      transition: background-color .3s ease, color .3s ease;
      cursor: pointer;
      margin-left: auto;

      &:hover {
        background-color: $primary-color;
        color: $primary-light-color;
      }

      svg {
        display: block;
      }
    }

    .icon-info {
      margin-left: 3px;

      svg {
        font-size: 1.125rem;
        color: $nonary-text-color;
        display: block;
      }
    }
  }

  &__fields {

    label.form-input__label,
    label.textarea__label {
      font-weight: 500;
    }
    
    .custom-select--form {
      // max-width: 200px;
    }

    .fields-wrapper--fullwidth {
      margin-bottom: 20px;

      label {
        margin-bottom: 8px;
      }

      .form-input {
        margin-bottom: 0;
      }
    }

    .fields-wrapper--tags {
      display: flex;
      justify-content: space-between;
      max-width: 200px;
      margin-bottom: 22px;

      .form-input {
        max-width: 128px;
        margin-bottom: 0;
      }

      .custom-select {
        width: 54px;
        align-self: flex-end;
        margin-bottom: 0;

        &__selected {
          font-size: 1.0625rem;
          color: $primary-dark-color;

          .text {
            overflow: visible;
          }

          .icon {
            right: 0;
          }
        }
      }
    }

    .fields-wrapper--dates {
      display: flex;

      > :first-child {
        margin-right: 17px;
      }

      > * {
        width: 139px;
      }

      .form-input--form-el .form-input__label {
        margin-bottom: 8px;
      }

      .tacit-renewal {

        .custom-select__label {
          white-space: nowrap;
        }

        .select-dropdown ul li:first-child {
          display: none;
        }
      }

      .react-datepicker__input-container input {
        background-image: url('../../assets/images/date_range-icon.svg');
        background-repeat: no-repeat;
        background-position: right 8px center;
        background-size: 24px 24px;
        padding-right: 30px;

        &.hide-bg-image {
          background-image: none;
        }
      }
    }

    .custom-select--form {
      // max-width: 232px;
    }

    .date_picker {
      margin-bottom: 22px;

      &__label {
        font-weight: 500;
      }
    }

    .add-btn {
      height: auto;
      margin-bottom: 15px;
      padding-top: 8px;
      padding-bottom: 9px;
    }
  }

  &__alert-notice {
    background-color: $nonary-color;
    padding: 14px 16px;
    border-radius: 3px;
    font-size: 0.9375rem;
    color: $nonary-text-color;

    a {
      color: $primary-color;
    }

    span {

      &.address {
        color: $septenary-text-color;
      }
    }
  }

  &__attachments {

    &_att {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 9px;
      border-radius: 3px;
      transition: background-color .3s ease;

      &:hover {
        background-color: $tertiary-bg-color;

        .name span {

          &.img,
          &.pdf,
          &.other {
            color: $septenary-text-color;
          }
        }

        .actions {
          opacity: 1;
        }
      }

      .name {
        display: flex;
        align-items: center;
        font-size: 0.9375rem;
        color: $secondary-text-color;
        word-break: break-all;

        span {
          transition: color .3s ease;

          &.img {
            color: $att-color-1;
          }
          &.pdf {
            color: $att-color-2;
          }
          &.other {
            color: $att-color-3;
          }
        }

        svg, i {
          display: block;
          font-size: 1.5rem;
          margin-right: 9px;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        opacity: 0;
        transition: opacity .3s ease;

        span {
          color: $septenary-text-color;
          cursor: pointer;
          margin-left: 5px;

          svg {
            display: block;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    .button {
      height: 36px;
      font-size: 0.9375rem;

      &:first-child {
        margin-right: 15px;
      }
    }

    .icon-button.alert-delete {
      margin-left: auto;
      width: 36px;
      height: 36px;
      color: $nonary-text-color;
      transition: color .3s ease, background-color .3s ease;

      &:hover {
        color: $secondary-color-light;
        background-color: rgba($secondary-color-light, 0.1);
      }
      &:active {
        color: $secondary-color-light;
        background-color: rgba($secondary-color-light, 0.3);
      }
    }
  }

  .textarea {
    margin-bottom: 22px;
  }

  .add-attachment-from-url {
    margin-bottom: 15px;
    margin-top: 20px;

    &__head {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $form-el-bg-color-1;
      border: 1px solid $form-el-border-color-1;
      padding: 7px;
      border-radius: 2px;
      margin-bottom: 15px;

      svg {
        font-size: 1.5rem;
        margin-right: 11px;
        color: $nonary-text-color;
        opacity: 0.87;
      }

      p {
        font-size: 0.875rem;
        color: $nonary-text-color;
      }

    }

    form {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $form-el-border-color-1;
      background-color: $form-el-bg-color-1;
      border-radius: 3px;
      padding: 2px 5px;
      transition: border-color .3s ease;

      &.active {
        border: 2px solid $primary-color;
        background-color: $primary-light-color;
        padding: 5px;

        .icon {
          display: none;
        }

        .button {
          display: flex;
        }

        input {
          flex: 1;
          padding-right: 10px;
          min-width: auto;
          padding-left: 13px;
        }
      }

      .icon {
        
        svg {
          font-size: 1.5rem;
          margin-right: 11px;
          color: $nonary-text-color;
          opacity: 0.87;
          display: block;
        }
      }

      input {
        // background-image: url('../../assets/images/http-icon.svg');
        // background-repeat: no-repeat;
        // background-position: left center;
        background-color: transparent;
        border: 0;
        outline: 0;
        height: 32px;
        display: block;
        font-size: 0.875rem;
        color: $secondary-text-color;
        min-width: 230px;
      }

      .button {
        display: none;
      }
    }
  }

  .paste-attachments {
    margin-top: 20px;

    .button {
      padding: 0 10px;

      i {
        font-size: 1.25rem;
      }
    }
  }

  .alerts-section {

    .single-alert {

      &--in-list {
        padding-top: 15px;
      }
    }

    ul {

      li {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #f2f2f2;
        position: relative;
        padding-right: 25px;

        p {
          font-size: 0.875rem;
          cursor: pointer;
        }

        .hover-actions {
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: opacity .3s ease;

          a {
            color: #34353B;

            svg {
              display: block;
              font-size: 1.25rem;
            }
          }
        }

        &:hover {

          .hover-actions {
            opacity: 1;
          }
        }
      }
    }

    &__add-new {
      margin-top: 30px;

      a {
        color: $quinary-text-color;
        font-weight: 500;
        font-size: 0.875rem;
        display: inline-flex;
        align-items: center;
        transition: color .3s ease;
    
        svg {
          display: block;
          margin-right: 5px;
          font-size: 1.5rem;
          color: $primary-color;
        }

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}