@use '../abstracts/variables' as *;

.contract-picker {
  margin-bottom: 22px;

  &.active {

    .contract-picker__field {
      border-color: $primary-color;
    }
  }
  
  &__label {
    font-size: 0.9375rem;
    color: $secondary-text-color;
    margin-bottom: 8px;
  }
  
  &__field {
    height: 36px;
    border: 1px solid $form-el-border-color-1;
    background-color: $form-el-bg-color-1;
    border-radius: 2px;
    padding: 0 7px;
    display: flex;
    align-items: center;
    position: relative;
    transition: border .3s ease;

    .button {
      padding-left: 7px;
      padding-right: 8px;
      overflow: hidden;
      font-size: 0.8125rem;
      font-weight: 400;
      display: flex;

      .icon {
        margin-left: 0;
        margin-right: 5px;

        svg {
          font-size: 1.25rem;
        }
      }

      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        display: block;
        min-width: 0;
      }
    }
  }

  &--thick-border {

    .contract-picker__field {
      border-width: 2px;
    }
  }

  &--error {

    .contract-picker__field {
      border-color: $secondary-color;
    }
  }

  &--no-wrapper {

    .contract-picker__field {
      height: auto;
      border: 0;
      background-color: transparent;
      padding: 0;
      border-radius: 0;
    }
  }

  &--btn-style-2 {

    .contract-picker__field {

      .button {
        color: $secondary-text-color;
        background-color: $primary-light-color;
        border-color: $senary-border-color;
        height: auto;
        padding-left: 5px;
        padding-right: 11px;
        
        .icon svg{
          font-size: 1.125rem;
        }
      }
    }
  }
}