@use '../abstracts/variables.scss' as *;

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  font-family: $ff-tertiary-bold;
  border-radius: 6px;
  cursor: pointer;
  height: 45px;
  font-size: 1rem;
  line-height: 1.1875;
  padding: 0 23px;
  letter-spacing: 0.34px;
  transition: background-color .3s ease;

  &--lg {
    height: 59px;
    padding: 0 27px 0 20px;
    font-size: 1.125rem;
    line-height: 1.27;
    letter-spacing: 0.39px;
    border-radius: 8px;

    svg {
      margin-right: 16px;
    }
  }

  &--green {
    background-color: #19E678;
    color: #fff;

    &:hover {
      background-color: darken(#19E678, 5);
    }

    &:active {
      background-color: darken(#19E678, 10);
    }
  }

  svg {
    display: block;
  }
}