@use '../abstracts/variables' as *;

.landing-api-section {
  padding: 126px 0 116px;
  background-color: #0A2540;
  position: relative;
  overflow: hidden;

  &__text {
    max-width: 448px;
    position: relative;
    z-index: 1;
    margin-bottom: 69px;

    @media (min-width: 1280px) {
      max-width: 665px;
    }

    @media (min-width: $break-lg-min) {
      max-width: 50%;
    }

    @media (max-width: 880px) {
      max-width: 100%;
    }

    .icon {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background-color: #06172C;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 13px;

      svg {
        color: #01D5FF;
        display: block;
        font-size: 2.125rem;
      }
    }

    h2 {
      color: $primary-light-color;
      line-height: 1.156;
      margin-bottom: 22px;
    }

    p {
      font-family: $ff-tertiary-medium;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.444;
      color: rgba($primary-light-color, 0.56);
      margin-bottom: 21px;
    }

    .button {
      height: 30px;
      font-size: 0.8125rem;

      .text {
        align-items: center;
        display: flex;
        justify-content: center;

        svg {
          margin-left: 3px;
        }
      }
    }
  }

  &__examples {

    h3 {
      margin-bottom: 28px;
      font-size: 0.9375rem;
      font-family: $ff-tertiary-medium;
      line-height: 1.33;
      color: #98A3AE;
      font-weight: 500;
    }

    &_inner {
      display: flex;
      flex-wrap: wrap;
      
      &-box {
        max-width: 216px;
        margin-right: 29px;
        margin-bottom: 20px;

        &:last-child {
          margin-right: 0;
        }

        img {
          display: block;
          max-width: 100%;
          max-height: 47px;
          height: 47px;
          margin-bottom: 20px;
        }

        h4 {
          font-size: 0.875rem;
          font-family: $ff-tertiary-medium;
          line-height: 1.5;
          color: #01D5FF;
          margin-bottom: 8px;
          font-weight: 500;
        }

        p {
          font-size: 0.875rem;
          line-height: 1.5;
          color: rgba($primary-light-color, 0.8);
        }
      }
    }
  }

  .preview {
    position: absolute;
    top: 160px;
    left: 56.5%;
    max-width: 100%;
    display: block;

    img {
      max-width: none !important;
      display: block;
    }

    @media (max-width: $break-lg-max) {
      max-width: 50%;
    }

    @media (max-width: 880px) {
      display: none;
    }
  }
}