@use '../abstracts/variables' as *;

.document-detail-variables {
  padding: 39px 36px;
  // padding-bottom: 800px;

  @media (max-width: $break-sm-max) {
    padding: 30px 15px;
  }

  &__head {
    margin-bottom: 32px;

    &_top {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-input {
        width: 140px;
        margin-bottom: 0;
      }

      .doc-variable-el--with-icon {
        display: inline-block;

        .icon {
          right: 0px !important;
        }
      }

      @media (max-width: $break-sm-max) {
        display: block;

        h2 {
          margin-bottom: 10px;
        }
      }
    }

    h3 {
      color: $primary-text-color;
      font-size: 1.125rem;
      font-weight: 500;
    }
  }

  &__body {

    section {
      margin-bottom: 50px;
    }

    h4 {
      color: $primary-text-color;
      font-weight: 700;
      margin-bottom: 16px;

      span {
        border-bottom: 2px solid $primary-dark-color;
      }
    }
  }
  .select_input_wrapper {
    margin-left: 10px;
    margin-right: 10px;

    &.multiselect {

      div {
        display: flex;
        align-items: center;
      }
    }
  }
  .date_picker {
    margin-left: 10px;
    margin-right: 10px;
  }
}